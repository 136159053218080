<template>
    <div id="app" class="container">
    
        <h1 class="mb-15">
            Magic2Chart
        </h1>
        <div class="row align-items-start h-50">
            <div class="col">
                <div class="row mb-3">
                    MagicWord:
                </div>
                <div class="row mb-3 h-25">
                    <textarea v-model="prompt" class="w-100"></textarea>
                </div>
                <div class="row mb-3">
                    <button class="btn btn-primary" @click="text2json">生成</button>
                </div>
            </div>
            <div class="col">
                <table class="table table-striped">
                    <tr>
                        <td>title</td>
                        <td>{{ result.title }}</td>
                    </tr>
                    <tr>
                        <td>type</td>
                        <td>{{ result.type }}</td>
                    </tr>
                    <tr>
                        <td>sql</td>
                        <td>{{ result.sql }}</td>
                    </tr>
                    <tr>
                        <td>column</td>
                        <td>{{ result.columns }}</td>
                    </tr>

                </table>
            </div>
            <div class="col">
                效果
                <div v-if="chartData.card_id">
                    <CardComponent
                    :card_id="chartData.card_id"
                    :title="chartData.title"
                    :type="chartData.type"
                    :columns="chartData.columns"
                    :data="chartData.data"
                    />
                </div>
            </div>
         
        </div>
   </div>

 </template>
 
 <script>
   /* eslint-disable */
 import api from '../common/api'; 
 import CardComponent from '../components/Card.vue';
 import 'bootstrap/dist/css/bootstrap.min.css';
 export default {
   name: 'App',
   components: {
    CardComponent
   },
   data() {
     return {
         result : {
            title: 'Hello',
            type: 'list',
            sql: 'select * from stocks',
            columns: 'hello'
         },
         chartData : {},
   }
   },
   created() {
     console.log('created')
   },
   methods: {
        text2json() {
            var _self = this
            api.post('/magic', {
                prompt: this.prompt,
                }).then(response => {
                    _self.result = response.data.data
                    _self.data2chart()
                    console.log(response.data)
                })
        },
        data2chart() {
            const page_id = 8
            const url = '/data?page_id=' + page_id + '&date=2024-07-18';
            api.get(url)
            .then(response => {
                
                const result = response.data.data;
                this.chartData = result[0]
            })
            .catch(error => {
                console.error('Fetching data failed', error);
                // reject(error); // 使用 reject 来传递失败原因
            });
        }
   }
 };
 </script>