<template>
  <main>
    <RouterView />
  </main>
</template>

<script>
  /* eslint-disable */
  import api from '@/common/api'; 
  import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
  },
  computed: {
      ...mapGetters(['account', 'isLogin'])
  },
  data: () => {
    return {
      transitionName: ''
    }
  },
  created() {
    this.updateTitle();
    this.checkLoginState();
  },
  mounted() {
    this.$watch(() => this.$route, (to, from) => {
      // if (to.meta.index > from.meta.index) {
      //   this.transitionName ='slide-left';
      // } else if (to.meta.index < from.meta.index) {
      //   this.transitionName ='slide-right';
      // } 
      // console.log(to, from);
      this.updateTitle();
    });
  },
  methods: {
    ...mapActions(['loginSuccess', 'logoutSuccess']),
      updateTitle() {
        const title = this.$route?.meta?.title || '默认标题';
        document.title = title;
      },
       // 首次打开自动登录
      checkLoginState() {
        api.get('/checkin').then(response => {
          const result = response.data
          if (result.status =='success') {
            console.log('自动登录成功')
          } else {
            // Token失败，清空Cookies
            // Toast.error('登录无效')
            document.cookie = 'jwt=; path=/; max-age=0';
            var token = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1];
            this.logoutSuccess()
          }
        })
      },
  }
};
</script>
<style scoped>


</style>