<template>

    <div class="h5-page">
        <div class="home-logo">
            <img src="https://m.gututu.net/images/icon.png" alt="logo" width="80px" style="border-radius: 15px"/>
            <div class="text">
                        <div class="slogan">股图图，AI金融分析画板</div>
                        <div class="desc">输入文字，大模型生成📈图表<br>
                            A港美股💰数据，准确性🎓更可靠<br></div>
            </div>
        </div>

        <div style="width: 100%; text-align: center;">
            <img src="https://m.gututu.net/images/mini-qrcode.jpg">
            <div>扫码使用小程序</div>
        </div>
        
        <div style="position: absolute; widows: 100%; text-align: center; left: 50%; transform: translateX(-50%); bottom: 10px;">
            <t-footer text="Copyright © 2024 深圳市小鲸滔滔数据服务有限公司"/>
            <t-footer text="粤ICP备2024295141号"/>
        </div>
       
    </div>
  </template>
  
  <script>
   /* eslint-disable */
 
  export default {
   name: 'Stock',
   components: {
   
   },
   data() {
      return {
      

      };
    },
   created() {

   },
    methods: {


    },
  };
  </script>
  
  <style scoped>
   .home-logo {
      margin-top: 20px;
      width: 100%;
      height: 200px;
      text-align: center;
   }

   .text {
      width: 380px;
      height: 80px;
      text-align: center;
      padding: 20px;
      /* padding-left: 80px; */
      margin: 0 auto;
      /* display: flex; */
   }

   .text .slogan {
      font-weight: bold;
      color: #222;
      font-size: 17px;
      margin-bottom: 5px;
   }
   .text .desc {
      color: #777;
   }

  </style>