<template>
  <div class="h5-page">
    <t-navbar title="修改资料" :fixed="false" left-arrow @left-click="goBack">
  </t-navbar>

  <t-loading theme="dots" size="40px" v-if="isLoading"/>
  
    <t-form
    ref="form"
    :data="formData"
    :rules="rules"
    reset-type="empty"
    show-error-message
    label-align="top"
    :disabled="disabled"
    scroll-to-first-error="auto"
    @reset="onReset"
    @submit="onSubmit"
  >
    <t-form-item label="昵称" name="name" help="不超过12个字">
      <t-input v-model="formData.name" borderless placeholder="输入昵称"></t-input>
    </t-form-item>

    <t-form-item label="个人简介" name="profile">
      <t-textarea
        v-model="formData.profile"
        class="textarea"
        indicator
        :maxlength="100"
        placeholder="请输入个人简介"
       style="width:100%;height:80px;"></t-textarea>
    </t-form-item>

    <!-- <t-form-item label="上传头像" name="photo">
      <t-upload
        :default-files="formData.avatar"
        multiple
        :max="8"
        :action="action"
        :on-fail="onFail"
        :on-progress="onProgress"
        :on-change="onChangeUpload"
        :on-preview="onPreview"
        :on-success="onSuccess"
        :on-remove="onRemove"
        :on-select-change="onSelectChange"
      >
      </t-upload>
    </t-form-item> -->

    <div class="button-group " style="padding: 16px 16px; width: 100%;text-align: center;">
      <t-button theme="primary" variant="light" type="submit" size="large" @click="modify" style="width:90%">保存</t-button>
    </div>

    </t-form>

  </div>
    
 </template>
 
 <script>
   /* eslint-disable */
import api from '../../common/api'; 
import { Toast } from 'tdesign-mobile-vue';
import { mapGetters, mapActions } from 'vuex';

export default {
   name: 'Modify',
   components: {
     
   },
   computed: {
      ...mapGetters(['account'])
     },
   data() {
     return {
       isLoading: false,
       formData: {
         name: '',
         profile: '',
         image: null,
         photo: [],
       }
     }
   },
   created() {
     console.log('created')
     this.loadProfile()

   },
   methods: {
    ...mapActions(['loginSuccess']),
      goBack() {
        this.$router.back();
      },
      loadProfile() {
        if (this.account)
        {
          console.log(this.account)
          this.formData.name = this.account.userName
          this.formData.profile = this.account.profile
        }
        return
        this.isLoading = true
        api.get('/profile').then(response => {
          const result = response.data
          console.log(result)
          if (result.status == 'success') {
            this.formData = result.data
          } 
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.isLoading = false
        })
      },
      onSelectChange(e) {
        this.image = e.target.files[0];
      },
      modify() {
        const params = this.formData
        // formData.append('file', this.image);
        // {headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }}
        if (this.formData.name == '') {
          Toast("请输入昵称")
          return
        }

        if (this.formData.name.length > 12) {
          Toast("昵称不能超过12个字")
          return
        }

        if (this.formData.profile.length > 100) {
          Toast("简介不能超过100个字")
          return
        }

        api.post('/profile/update', params).then(response => {
          const result = response.data
          if (result.status == 'success') {
            Toast.success('修改成功')
            
            this.loginSuccess(result.account)
          } else {
            Toast.error('修改失败')
          }
        })
      }
   }
 };
 </script>