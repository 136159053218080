<template>
  <div class="h5-page">
    <div class="login">
     <div class="close-btn" @click="close()"> <icon-font name="close" /> </div>

     <!-- <div class="loading-bg page-center" v-if="is_loading">
        <t-loading theme="dots" size="40px" class="page-center"/>
    </div> -->

    <G-Loading v-if="is_loading" />

     <div style="margin-left:15px; padding-bottom:45px;">
        <img src="https://m.gututu.net/images/icon.png" alt="logo" width="60px" style="border-radius: 10px; float: left; margin-right: 15px;" />
        <div><h3>股图图</h3></div>
        <div style="font-size: 14px; color: #666;">AI金融分析画板</div>
     </div>

     <!-- <h3 style="margin-left:15px;">登录或注册</h3> -->

     <t-input v-model="phoneNumber" label="手机号" placeholder="输入手机号码">
      
        <template #suffix>
        <div class="suffix">
            <div class="suffix--line"></div>
            <div class="verify" aria-role="button" @click="getVerifyCode">{{ codeBtnText }}</div>
        </div>
        </template>
    </t-input>
    <t-input ref="inputCode" label="验证码" v-model="code" placeholder="输入验证码"></t-input>

    
     <div class="row">
        <t-button size="large" theme="primary" block @click="checkForm" style="margin: 0 auto; width: 80%; border-radius: 30px; border: 0px; margin-top:30px;">登录</t-button>
     </div>

    <div style="margin-top: 30px;margin-left: 15px;" v-if="false">
      或使用以下帐号登录
    <button @click="loginByWechat">微信登录</button>
    </div>

    <div class="footer"> 
      <input type="radio" v-model="agree" checked> 我已阅读并同意 <span class="link" @click="gotoPage('agreement')">《服务条款》</span>和<span class="link" @click="gotoPage('privacy')">《隐私政策》</span>
    </div>
   </div>
  </div>
   
 </template>
 
 <script>
   /* eslint-disable */
// import NEOneLogin from '@yidun/quickpass-sdk-one-login-h5';
import api from '@/common/api'; 
import { Toast } from 'tdesign-mobile-vue';
import { IconFont } from 'tdesign-icons-vue-next';
import { mapState } from 'vuex';
import { mapGetters, mapActions } from 'vuex';
import { isWechatEnv, isMiniApp } from '@/common/tools'
import Loading from '@/components/Loading';

// import wxlogin from 'vue-next-wxlogin';
// Vue.component('wxlogin', wxlogin);
// https://github.com/toMatthew/vue3-wxlogin
/* <wxlogin :appid="appid" :scope="scope" :redirect_uri="redirect_uri"></wxlogin> */
 // 使用access_token获取用户信息

 //  const userInfoResponse = await fetch(`https://api.weixin.qq.com/sns/userinfo?access_token=${tokenData.access_token}&openid=${tokenData.openid}&lang=zh_CN`);
//   const userInfoData = await userInfoResponse.json();
  
//   userInfo.value = userInfoData;
// };

 
 export default {
   name: 'Login',
   components: {
    IconFont
   },
   computed: {
    ...mapState(['account']),
  },
   data() {
     return {
       phoneNumber: '',
       code: '',
       tips: '请输入手机号码',
       agree: true,
       from_path: '',
       is_loading: false,
       count_down: 30,
       codeBtnText: '发送验证码'
     };
   },
   created() {
      this.from_path = this.$route.query.from || ''
   },
   methods: {
     ...mapActions(['loginSuccess']),
      close() {
        if (this.from_path == '/mine')
          this.$router.replace({ path: '/' });
        else
          this.$router.back()
      },
      gotoPage(page) {
        this.$router.push('/' + page);
      },
      loginByWechat() {
        Toast('请使用微信登录')
        // const neOneLogin = new NEOneLogin({
        //   businessId: 'cd2e2ffe69c3428eb47f0e9132b12913',
        //   logo: 'https://m.gututu.net/images/icon.png',
        //   phoneInputStyle: 'square',
        // });
        // neOneLogin.getToken();
      },
      checkForm() {
        if (!this.agree) {
          Toast('请选择同意服务条款和隐私政策') 
          return
        }
        if (this.phoneNumber == '') {
          Toast('请输入手机号') 
          return
        }
        if (this.phoneNumber.length != 11) {
          Toast('请输入正确的手机号') 
          return
        }
        if (this.code == '') {
          Toast('请输入验证码') 
          return
        }
        if (this.code.length != 4) {
          Toast('验证码不正确') 
          return
        }
        this.login()
      },
      login() {
        this.is_loading = true;
        let openid = ''
        if (isWechatEnv()) {
          openid = localStorage.getItem('wechat_openid')
        } else if (isMiniApp()) {
          openid = localStorage.getItem('miniapp_openid')
        }
      
        var params = {
           type: 'phone',
           phone: this.phoneNumber,
           code: this.code,
           openid: openid || ''
        }
        api.post('/login', params).then(response => {
          const result = response.data
          if (result.status == 'success') {
            // console.log('token: ' + result.token)
            this.saveToken(result.token)
            this.loginSuccess(result.account)
            Toast('登录成功')
            if (this.from_path != '')
              this.$router.push(this.from_path + '?from=login');
            else
              this.gotoPage('')
          } else {
            Toast(result.msg)
          }
        })
        .catch(error => {
          console.log(error)
          Toast('出错了')
        })
        .finally(() => {
          this.is_loading = false
        })
      },
      saveToken(token) {
        document.cookie = `jwt=${token};path=/;max-age=${60*60*24*7};`;
      },
      sendSMS() {
        api.post('/sms', {phone: this.phoneNumber}).then(response => {
          const result = response.data
          if (result.status =='success') {
            Toast('验证码发送成功')
          } else {
            Toast(result.msg)
          }
        })
      },
      getVerifyCode() {
        if (this.phoneNumber == '') {
          Toast('请输入手机号') 
          return
        }
        if (this.phoneNumber.length != 11) {
          Toast('请输入正确的手机号') 
          return
        }

        this.$nextTick(() => {
          this.$refs.inputCode.focus();
        });

        if (this.count_down == 30) {
          this.sendSMS();
          this.codeBtnText = '已发送(' + this.count_down + '秒)';
          const intervalId = setInterval(() => {
            this.count_down--;
            this.codeBtnText = '已发送(' + this.count_down + '秒)';
            if (this.count_down == 0) {
              clearInterval(intervalId);
              this.codeBtnText = '发送验证码'
              this.count_down = 30
            }
          }, 1000);
        }
      }
   },
 };
 </script>
 <style scoped>
.login{
  margin-top: 30px;
  padding: 20px;
}

.footer {
  margin-top: 50px;
  margin-left: 15px;
  font-size: 13px;
  color: #666;
}
.link {
  /* text-decoration: underline; */
  color: #333;
  cursor: pointer;
}

.t-button--primary::after {
    border-width: 0px;
    border-color: #FFFFFF;
}

.close-btn {
  top: calc(15px + constant(safe-area-inset-top));
  top: calc(15px + env(safe-area-inset-top));
  position: fixed; 
  right: 15px; 
  font-size: 20px; 
  color: #666;
}
</style>