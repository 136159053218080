<template>
   
    <div id="app" class="container">
      <t-navbar title="修改卡片" :fixed="false" left-arrow @left-click="goBack" />
    
        <t-cell> 
          <t-radio-group :default-value="card.type" :value="card.type" @change="typeChange">
                    <t-radio :block="false"  value="list" label="列表" default/>
                    <t-radio :block="false"  value="bar"  label="柱状图"/>
                    <t-radio :block="false"  value="pie"  label="饼图"/>
                    <t-radio :block="false"  value="line"  label="折线图"/>
          </t-radio-group>
        </t-cell>
        <t-cell>
          <t-textarea
                v-model="card.title"
                class="textarea"
                indicator
                :maxlength="100"
                placeholder="SQL"
              style="width:100%;height:60px;">
              </t-textarea>

        </t-cell>
        <t-cell>
          <t-textarea
                v-model="card.magic_word"
                class="textarea"
                indicator
                :maxlength="200"
                placeholder="提示语"
              style="width:100%;height:80px;">
              </t-textarea>

        </t-cell>

        <t-cell>
          <t-textarea
                v-model="card.columns"
                class="textarea"
                indicator
                :maxlength="200"
                placeholder="列名"
              style="width:100%;height:60px;">
              </t-textarea>

        </t-cell>
        <t-cell>

              <t-textarea
                v-model="card.query_sql"
                class="textarea"
                indicator
                :maxlength="1000"
                placeholder="SQL"
              style="width:100%;height:300px;">
              </t-textarea>
        </t-cell>
        <t-cell>
            <button class="btn btn-primary" style="width: 90%;margin:15px;" @click="submitForm()">保存</button>
          </t-cell>
    </div>
   
 </template>
 
 <script>
   /* eslint-disable */
 import api from '@/common/api'; 
 import 'bootstrap/dist/css/bootstrap.min.css';
 import { Toast } from 'tdesign-mobile-vue';
 
 import { onMounted } from 'vue';
 import { useCardDataStore } from '@/stores/CardDataStore';

 
 export default {
   name: 'App',
   components: {
     
   },
   data() {
     return {
         card: {},
         showMessage: false,
         dataStore: useCardDataStore(),
     }
   },
   created() {
     this.loadData()
    
   },
   methods: {
    goBack() {
      this.$router.back()
    },
    typeChange(value, content) {
      console.log('gututu', value, this.card.type);
    },
    loadData() {
      var url = '/cards/' + this.$route.params.card_id
      api.get(url)
       .then(response => {
          this.card = response.data.card

          console.log('gututu', this.card.type);
       })
       .catch(error => {
         console.error('Fetching data failed', error);
       });
    },

    submitForm() {
      api.post('/cards/update_prompt', this.card).then(response => {
        if (response.data.status == 'success') 
        {
          Toast('修改成功')
          this.dataStore.removeCard(this.card.card_uuid)
          this.showMessage = true
        }
        // this.$router.back();
      })

    }
   }
 };
 </script>

 
<style scoped>
textarea {
  height: 300px;
}
</style>