<template>
    <div class="disclaimer">
        <div style="font-size: 14px; color: #666;margin-bottom: 5px;">数据更新截止到 {{ date }}(北京时间)</div>
        <div style="font-size: 14px; color: #666;margin-bottom: 5px;font-weight: bold;">重要提示和声明</div>
        <div style="font-size: 14px; color: #666;">
        本页面内容由AI提炼生成，不代表股图图官方立场，不构成投资建议。
        如需详细阅读有关提示和声明，请<span @click="longDisclaimer = !longDisclaimer" style="color:#666; font-weight: bold;text-decoration: underline;">点击此处</span>
      </div>
        <div style="font-size: 14px; color: #666;margin-top:10px;" v-if="longDisclaimer">
          1. 本页面内容由AI基于股图图用户输入的分析思路生成，仅供用户作一般性的参考阅读使用，不构成投资建议。<br><br>
          2. 本页面提及的观点不代表股图图官方立场，亦不代表股图图对其中任何行业或相关公司的判断；本页面如提及任何投资标的，亦仅基于一般举例和参考目的，不应被视为投资建议。<br><br>
          3. 虽然本应用数据源自专业的金融交易数据，但AI仍处于早期发展阶段，在技术上尚不成熟，且用户分析内容具有UGC属性，股图图无法保证AI生成的图表完整、准确。若涉及对你或其他相关方可能产生重大影响的情形，建议你采取合理必要措施对AI提炼生成内容进行核实、并咨询相关专业机构和专业人士，本页面内容不应成为你进一步作为或不作为的依据。<br><br>
          4. 在法律允许的限度内，股图图保留对本页面内容的一切权利。任何机构或个人不得擅自对本页面内容进行全部或部分的复制、改编或其他使用，不得擅自将本页面内容用于未经股图图同意的用途（包括商业用途或其他用途）。<br><br>
          5. 投资有风险，决策需谨慎。在任何情况下，股图图不对任何人因使用本页面的任何内容所导致的损失承担任何责任。
        </div>
      </div>
</template>
    
<script>
/* eslint-disable */
import dayjs from 'dayjs';
export default {
    name: 'Disclaimer',
    props: {
        date: {
            type: String,
            default: dayjs().format('YYYY-MM-DD')
        }
    },
    data() {
        return {
            longDisclaimer: false,
        }
    },
};
</script>
<style scoped>
  .disclaimer {
    width: 100%;
    padding: 15px;
    padding-top: 0px;
    font-size: 12px;
    color: #aeb0bb;
  }
</style>