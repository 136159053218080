  /* eslint-disable */

import { isWechatEnv } from './tools'
import api from './api'; 

let getUrlCodeParam = (name) => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
  }

export let getOpenId = async () => {
    return new Promise(async (resolve, reject) => {
      console.log("初始化");
      let openid = "";
    //   let ua = navigator.userAgent.toLowerCase();

      if (isWechatEnv()) {

        if (!localStorage.getItem("wechat_openid")) {
          console.log("微信内部环境");
   
          const code = getUrlCodeParam('code')

          if (code) {
            // 接口返回openid
            api.post('/get_wechat_openid', {code: code})
                .then(response => {
                    const result = response.data
                    if (result.status == "success") {
                        openid = result.data.openid
                        // 拿到openid后将openid存到本地
                        localStorage.setItem("wechat_openid", openid ? openid : "");
                    }
                });
          } else {
            let redirect_url = encodeURIComponent(window.location.href);
            // 股图图公众号的appid
            let app_id = "wx77256e2071aab996";
            
            let url =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              app_id +
              "&redirect_uri=" +
              redirect_url +
              "&response_type=code&scope=snsapi_base&state=1#wechat_redirect";
            
            window.location.href = url;
          }
          // 这块返回了openid
          return resolve(openid ? openid : "");
        } else {
        // 判断接口有openid就直接获取本地的
          openid = localStorage.getItem("wechat_openid");
          return resolve(openid ? openid : "");
        }
      } else {
        console.log("不在微信环境内");
        return resolve("");
      }
    });
  }