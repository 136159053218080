<template>
    <div class="h5-page with-nav-bar">
     <t-navbar :title="title" :fixed="true" left-arrow @left-click="goBack"></t-navbar>
  
      <G-Loading v-if="isLoading" />

        <div id="app" class="page-bg">
          <div class="failure" v-if="connect_failure">
            <t-result theme="warning" title="网络异常" description="请检查手机网络信号是否正常" class="space" />
          </div>
          
          <t-tabs default-value="0"  @change="onChange">
            <!-- <t-tab-panel value="0" label="我的" v-if="isLogin"/> -->
            <t-tab-panel value="0" label="所有" />
            <t-tab-panel value="1" label="会员" />
          </t-tabs>

          <div class="empty-tip" v-if="users.length == 0">
            <t-result theme="default" title="没有画板" :description="这是一段描述" class="space" />
          </div>
        
          <div class="infinite-scroll-list page-list">
            <div class="user infinite-scroll-item" v-for="user in users" :key="user.user_id" @click="showUser(user.user_uuid)">
                <div class="name">
                    <t-image class="avatar" :src="user.avatar" shape="round" />
                    <span>{{user.name}}</span>
                    <img src="https://m.gututu.net/images/vip.png" width="18" height="18" v-if="user.vip_level > 0">
                </div>
                <div class="row">
                 
                </div>
            </div>
          </div>
  
          <YiInfiniteLoading
            :loading="isLoading"
            :finished="finished"
            offset="100%"
            :hideLoading="true"
            @loadMore="loadMore"
          >
          <t-loading theme="dots" size="40px" v-if="isLoading"/>
  
            <!-- <span class="tips" v-if="isLoading">正在加载</span> -->
            <!-- <span class="tips" v-if="finished && pages.length > 0">没有更多</span> -->
          </YiInfiniteLoading>
            
          
      </div>
      
    </div>
   </template>
   
   <script>
     /* eslint-disable */
     
   import api from '../common/api'; 
   import { Toast } from 'tdesign-mobile-vue';
   import { mapGetters } from 'vuex';
   import { IconFont } from 'tdesign-icons-vue-next';
   import YiInfiniteLoading from 'yi-infinite-loading-vue3'
   import GuTabBar from '../components/GuTabBar.vue';
   
   export default {
     name: 'UserList',
     components: {
       IconFont,
       YiInfiniteLoading
     },
     computed: {
      ...mapGetters(['account', 'isLogin'])
     },
     data: () =>{
          return {
              title: '用户',
              type: 'all', 
              users: [],
              connect_failure: false,
              isLoading: false,
              finished: false,
              page_number: 1,
          }
     },
     created() {
        this.loadData()
     },
     methods: {
      goBack() {
        this.$router.back();
      },

      loadData() {
        if (this.finished)
          return;
          
        this.is_loading = true

        var url = '/users?page_num=' + this.page_number;
        if (this.type == 'vip')
          url = '/users?page_num=' + this.page_number + '&vip=1'

        api.get(url)
           .then(response => {
           const data = response.data.data;
   
           for (let item of data)
              this.users.push(item);
  
            if (data.length < process.env.VUE_APP_PAGE_SIZE)
              this.finished = true
  
            this.isLoading = false
         })
         .catch(error => {
           this.connect_failure = true
           console.error('Fetching data failed', error);
         });
      },
      // 重新加载
      // clear() {
      //   this.pages = []
      //   this.page_number = 1
      //   this.loadData()
      // },
      loadMore() {
        if (this.isLoading || this.finished) {
          return
        }
        this.isLoading = true
        this.page_number +=1;
        this.loadData()
      },
      onChange(event, label) 
      {
        this.finished = false
        // 处理事件
        if (label == '所有') { 
          this.type = 'all'
          this.users = []
          this.page_number = 1
        } else if (label == '会员') {
          this.type = 'vip'
          this.users = []
          this.page_number = 1
        }
        this.loadData()
      },
      showUser(user_id) {
        this.$router.push('/users/' + user_id);
      },
     }
   };
   </script>
   <style scoped>
   .user {
      padding: 15px;
      width: 100%;
      border: 1px solid #ECECEC;
      background: #FFF;
      margin-bottom: 10px;
      border-radius: 12px;
      height: 80px;
   }
  
   .search-bar {
  
      padding: 10px 5px;
      width: 100%;
      height: 60px;
   }
  
   .page .name {
      font-size: 16px;
      width:100%;
      clear:both;
   }
  
   .failure {
    height: 300px;
    padding-top: 80px;
   }
  
   .empty-tip {
      background: #FFF;
      padding: 100px 0px;
   }
   .tips {
    text-align: center;
   }

   .avatar {
      width: 40px;
      float: left;
      margin-right: 15px;
   }
   </style>