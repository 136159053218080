const UMTrackPV = (params) => {
    const {aplus_queue} = window;
    aplus_queue.push({
    action: 'aplus.sendPV',
    arguments: [{ is_auto: false }, params]
    });
}

const UMRecord = (code, params) => {
    const {aplus_queue} = window;
    aplus_queue.push({
        action: 'aplus.record',
        arguments: [code, 'CLK', params]
    });
}

export { UMTrackPV, UMRecord};