<template>
  
  <div id="app" class="container">
     <h3>注册</h3>

      
     <t-input v-model="phoneNumber" label="手机号" placeholder="输入手机号码" :tips="tips">
        <template #suffix>
        <div class="suffix">
            <div class="suffix--line"></div>
            <div class="verify" aria-role="button">发送验证码</div>
        </div>
        </template>
    </t-input>

     <t-input label="验证码" placeholder=""></t-input>
     <t-input label="昵称" placeholder="最多10个字符"></t-input>
     <t-input label="头像" placeholder=""></t-input>

     <div class="row">
        <t-button size="large" theme="primary" block>注册</t-button>
    </div>

   </div>
   
 </template>
 
 <script>
   /* eslint-disable */
 import axios from 'axios';
 
 export default {
   name: 'Signup',
   components: {
     
   },
   data() {
     return {
       phoneNumber: '',
       nickname: '',
       avatar: '',
       tips: '请输入手机号码',
     };
   },
   created() {

     
   },
   methods: {
      signup() {

      },
   },
 };
 </script>