<template>
    <div id="app">
      <t-navbar title="会员权益" :fixed="false" left-arrow @left-click="goBack">
      </t-navbar>

      <div class="page-header">
      <div  class="page-title">
        会员升级
      </div>
      
      <span style="color: #EFEFEF;margin-left:15px;" v-if="isVip || vip_level > 0">
        <img src="https://m.gututu.net/images/vip.png" width="18" height="18"> VIP版，有效期到{{vip_expire}}
      </span>
      <span style="color: #EFEFEF;margin-left:15px;" v-else>免费版</span>
    </div>

    <div class="content">
      <div class="product-list">
        
        <div :class="'product-item ' + (selected == 0 ? 'selected' : '')" @click="selectProduct(0)">
          <div class="coupon" v-if="selected == 0">
            限时优惠12元
          </div>
          <div class="product-title">
            1个月
          </div>
          <div class="product-price">
            <span class="money-icon">¥</span>38
          </div>
          <div class="old-price">
            原价50
          </div>
        </div>

        <div :class="'product-item ' + (selected == 1 ? 'selected' : '')" @click="selectProduct(1)">
          <div class="coupon" v-if="selected == 1">
            限时优惠52元
          </div>
          <div class="product-title">
            3个月
          </div>
          <div class="product-price">
            <span class="money-icon">¥</span>98
          </div>
          <div class="old-price">
            原价150
          </div>
        </div>

        <div :class="'product-item ' + (selected == 2 ? 'selected' : '')" @click="selectProduct(2)" style="margin-right:0px;">
          <div class="coupon" v-if="selected == 2">
            限时优惠302元
          </div>
          <div class="product-title">
            1年
          </div>
          <div class="product-price">
            <span class="money-icon">¥</span>298
          </div>
          <div class="old-price">
            原价600
          </div>
        </div>

        

      </div>
      <button @click="upgrade" class="pay-button">
        <span v-if="isVip">会员续费</span>
        <span v-else>立即开通vip</span>
      </button>
      

      <table class="t-table vip-table">
        <tr>
          <th>权益对比</th>
          <th>免费版</th>
          <th><img src="https://m.gututu.net/images/vip.png" width="18" height="18">VIP版</th>
        </tr>
        <tr>
          <th>浏览画板</th>
          <td>50个</td>
          <td>不限</td>
        </tr>
        <tr>
          <th>画板数量</th>
          <td>3</td>
          <td>100</td>
        </tr>
        <tr>
          <th>卡片数量</th>
          <td>15</td>
          <td>500</td>
        </tr>
        <tr>
          <th>查询次数</th>
          <td>20次</td>
          <td>100次/天</td>
        </tr>
        <tr>
          <th>生成结果</th>
          <td>10条</td>
          <td>50条</td>
        </tr>
        <!-- <tr>
          <th>股票数据</th>
          <td>A港美股</td>
          <td>A港美股</td>
        </tr> -->
        <tr>
          <th>私密画板</th>
          <td>不支持</td>
          <td>支持</td>
        </tr>
        <tr>
          <th>营收数据</th>
          <td>不支持</td>
          <td>支持</td>
        </tr>
        <tr>
          <th>交易数据</th>
          <td>2年内</td>
          <td>5年内</td>
        </tr>


      </table>



    </div>
      

      <div v-if="showQrcode" class="qrcode" @click="showQrcode = false">
        <div style="width:100%; padding: 15px;">请使用微信扫码支付</div>
        <div class="close-btn" @click="showQrcode = false"> <icon-font name="close" /> </div>

        <div style="padding:15px;text-align: center;">
            <canvas ref="canvas"></canvas>
        </div>
      </div>
   </div>

   
   
 </template>
 
 <script>
   /* eslint-disable */
 import api from '@/common/api'; 
 import 'bootstrap/dist/css/bootstrap.min.css';
 import { ref, onMounted } from 'vue';
import { Toast } from 'tdesign-mobile-vue';
import { isWechatEnv, isMiniApp } from '@/common/tools'
import { mapGetters, mapActions } from 'vuex';
import QRCode from 'qrcode';
import { IconFont } from 'tdesign-icons-vue-next';
import { useProfileStore } from '@/stores/ProfileStore';

 
 export default {
    setup() {
        onMounted(() => {
            
        });
    },

   name: 'Vip',
   components: {
    IconFont
   },
   computed: {
    ...mapGetters(['account', 'isLogin', 'isVip'])
   },
   data() {
     return {
       selected: 0,
       product_id: 1,
       btn_text: '立即开通vip',
       showQrcode: false,
       canvas: null,
       vip_level: 0,
       vip_expire: '',
       miniapp_pay: false,
       miniapp_pay_timer: null
     }
   },
   created() {
      this.loadProfile()
   },
   methods: {
    ...mapActions(['loginSuccess']),
      goBack() {
        // 如果是小程序，返回前先检查一下，是否付款成功  
        if (this.miniapp_pay_timer) {
          clearInterval(this.miniapp_pay_timer);
        } 
        this.$router.back();
      },
      // 调起支付接口
      // 流程：选择会员套餐，点击支付，调起支付接口
      // 支付成功后，跳转到会员权益页面
      // 默认套餐 月费38元

      selectProduct(index) {
        this.selected = index;
        this.product_id = [1, 3, 12][index]

        this.$UMRecord('SelectProduct', {product_id: this.product_id});
      },
      loadProfile() {
        api.get('/profile').then(response => {
          const result = response.data
          if (result.status == 'success') {
            this.vip_level = result.data.vip_level
            this.vip_expire = result.data.vip_expire

            if (this.vip_level != this.account.vip_level) {
              
              var updateAccount = this.account
              updateAccount.vip_level = this.vip_level
              this.loginSuccess(updateAccount)

              const profileStore = useProfileStore();
              profileStore.reset();
            }
          } 
        })
      },
      upgrade() {
        this.$UMRecord('pay', {product_id: this.product_id});

        var params = {product_id: this.product_id}
        // 判断是微信公众号支付还是小程序支付
        if (isMiniApp()) {
          // 小程序支付
          this.$UMRecord('pay', {channel: 'miniapp'});
          const openid = localStorage.getItem("miniapp_openid");
          params['openid'] = openid

          api.post('/orders/create', params).then(response => {
            const result = response.data
            if (result.status =='success') {
              this.miniappPay(result.order_no)
            }
          })
        } else if (isWechatEnv()){
          // 公众号支付
          this.$UMRecord('pay', {channel: 'wechat'});
          const openid = localStorage.getItem("wechat_openid");
          params['openid'] = openid
          
          api.post('/upgrade', params).then(response => {
            const result = response.data
            if (result.status =='success') {
              this.startPay(result.data)
            }
          })
        } else {
          this.$UMRecord('pay', {channel: 'qrcode'});
          // Toast('请在微信中打开本页面')
          api.post('/orders/qrcode', params).then(response => {
            const result = response.data
            if (result.status =='success') {
              this.payByQrcode(result.data)
            }
          })
        }

      },
      miniappPay(order_no) {
        var _this = this
        // 向小程序发送消息
        // window.wx.miniProgram.postMessage({data: order_no})
        this.miniapp_pay = true
        _this.miniapp_pay_timer = setInterval(function () {
              _this.loadProfile()
        }, 1500)

        // return
        window.wx.miniProgram.navigateTo({
          url: '/page/pay?order_no=' + order_no,
          fail: function (res) {
            // Toast('跳转失败')
          },
          success: function () {
            // Toast('跳转成功')
          }
        })
      },
      payByQrcode(code_url) {
        var _this = this;
        this.showQrcode = true;
        this.$nextTick(() => {
          if (this.$refs.canvas) {
            this.canvas = this.$refs.canvas;
          
            _this.miniapp_pay_timer = setInterval(function () {
              _this.loadProfile()
            }, 1500)

            QRCode.toCanvas(this.canvas, code_url, function (error) {
                if (error) console.error('Error generating QR code:', error);
            });
          }
        })        
      },
      // 公众号支付
      startPay(data) {
        var _this = this;
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
            "appId": data.appId,            //公众号ID，由商户传入    
            "timeStamp": data.timeStamp,    //时间戳，自1970年以来的秒数    
            "nonceStr": data.nonceStr,      //随机串    
            "package": data.package,
            "signType": "RSA",              //微信签名方式：    
            "paySign": data.paySign         //微信签名
        },
        function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              Toast('支付成功')
              _this.loadProfile()
            } else {
              Toast('支付失败，请重试')
            }
        });
      },
   }
 };
 </script>
 <style scoped>
 .page-header {
  height: 130px;
  padding: 10px;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}


.page-title {
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  margin-top:40px;
  margin-left:15px;
}

.coupon {
  position: absolute;
  /* z-index: 500; */
  top: -10px;
  left: -2px;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left:8px;
  width: 105px;

  background: linear-gradient(90deg, #ff7f48 .24%, #ff3d49 102.27%);
  border-radius: 10px 10px 10px 1px;
  color: #fff;
  font-size: 12px;
  height: 20px;

}

.content {
  width: 100%;
  padding: 0px 10px;
}

.product-list {
  width: 100%;
  display: flex;
  margin-top:15px;
  padding: 5px 0px;
}

.product-item {
  position: relative;
  width: 32%;
  padding: 10px;
  border-radius: 10px;
  margin-right:10px;
  border: 1px solid #666;
}

.selected {
  background-color: #FFE9AF;
  border: 2px solid #895E2C;
}

.product-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.product-price {
  margin-top:5px;
  margin-bottom:5px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.money-icon {
  font-size: 18px;
}

.old-price {
  font-size: 14px;
  text-align: center;
  text-decoration: line-through;
}

.pay-button {
  width: 100%;
  margin-top:10px;
  padding: 10px;
  background-color: #FFD46C;
  color: #6B4204;
  font-weight: bold;
  font-size: 18px;
  border: 0px;
  border-radius: 15px;
}

.vip-table {
  margin-top:15px;
  margin-bottom: 15px;
}

.vip-table th {
  font-size: 14px;
  
  text-align: center;
  background-color: #f9f0db;
  color: #000;
}

.vip-table td {
  text-align: center;
}

.qrcode {
  position: fixed;
  top: 25%;
  left: 10%;
  width: 80%;
  height: 300;
  background-color: #FFF;
  border: 1px solid #ECECEC;
  padding: 15px;
  z-index: 1000;
  border-radius: 20px;
}

.qrcode canvas {
  width: 100%;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
}
</style>