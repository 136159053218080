<template>
    <div class="h5-page with-nav-bar">

    <t-navbar title="关于我们" :fixed="true" left-arrow @left-click="goBack">
  </t-navbar>

    <div style="padding: 18px;" >
      <t-image src="https://m.gututu.net/images/icon.png" shape="round" style="width: 80px; margin: 0px auto;"/>

      <br>
      股图图，下一代的AI金融投资分析交流平台。<br>
      让金融数据分析更普惠，让金融投资更简单。<br>
 
      核心团队来自腾讯、微众银行、富途证券、支付宝等大厂。<br>
   </div>

    <div>
        <div class="member">
          <t-image class="avatar" src="https://m.gututu.net/images/avatar/avatar12.png" shape="round" />
          Sam
        </div>
        <div class="member">
          <t-image class="avatar" src="https://m.gututu.net/images/avatar/avatar10.png" shape="round" />
          Dan
        </div>
        <div class="member">
          <t-image class="avatar" src="https://m.gututu.net/images/avatar/avatar11.png" shape="round" />
          Jia
        </div>
        <div class="member">
          <t-image class="avatar" src="https://m.gututu.net/images/avatar/avatar09.png" shape="round" />
          Yang
        </div>
    </div>

    <div class="contact">
      <br>
      联系我们：<br>
      邮箱：hi@gututu.com
    </div>

  </div>


 </template>
 
 <script>
   /* eslint-disable */
import { Toast } from 'tdesign-mobile-vue';

 export default {
   name: 'About',
   components: {
     
   },
   created() {
     console.log('created')
   },
   methods: {
      goBack() {
        this.$router.back();
      },
   }
 };
 </script>
 <style scoped>
  .avatar {
    width: 100px;
    margin: 10px auto;
  }

 .member {
    float: left;
    align-items: center;
    width: 23%;
    text-align: center;
  }

  .contact {
    display: flex;
    margin-left: 20px;
    padding: 15px;
    width: 90%;
  }
</style>