// HotPageStore.js
import { defineStore } from 'pinia';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const useCanvasStore = defineStore('canvas', {
    state: () => ({
        prompt_limit: 3, // 未注册用户的限制
        prompt_used: 0,  // 已使用的次数
        pages: [],
        canvas_page_id: null, // 当前画板的page_id
        loadAt: null,
        prompts: [],
        rendering: false,  // 是否已生成
        createdAt: null,
        visitor_id: null,
        scrollPosition: 0
    }),

    actions: {
        getVisitorId() {
            var _this = this
            if (this.visitor_id != null)
                return this.visitor_id
            else {
                const fpPromise = FingerprintJS.load();
                fpPromise.then(fp => fp.get()).then(result => {
                    _this.visitor_id = result.visitorId;
                    return _this.visitor_id
                });
            }
        },

        create(prompt) {
            this.prompts.push(prompt);
            this.rendering = true
            this.canvas_page_id = null
        },
        done() {
            // 生成完成后，将rendering设置为false，防止重复生成
            this.rendering = false;
        },
        setPageId(pageId) {
            this.canvas_page_id = pageId;
        },
        currentPrompt() {
            if (this.prompts.length > 0)
                return this.prompts[this.prompts.length - 1]
            else
                return ''
        },
        saveScrollPosition(position) {
            this.scrollPosition = position;
        },
        restoreScrollPosition() {
            return this.scrollPosition;
        },
    }
});