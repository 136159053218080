<template>
    <t-navbar title="第三方共享信息" :fixed="false" left-arrow @left-click="goBack">
  </t-navbar>

    <div id="app" class="container">


   </div>
   
 </template>
 
 <script>
   /* eslint-disable */
   import 'bootstrap/dist/css/bootstrap.min.css';
//  https://terms.xiaoyuzhoufm.com/sdk-information/

 export default {
   name: 'Agreement',
   components: {
     
   },
   data() {
    return {
      update_date: '8月10日, 2024年',
      company_name: 'Gututu',
      email: 'hello@gututu.com',
    }
   },
   created() {

   },
   methods: {
      goBack() {
        this.$router.back();
      },
   }
 };
 </script>
 <style scoped>
    p {margin-top: 50px;}
 </style>