<template>
      <div class="h5-page page-bg">

      <!-- <t-navbar :title="title" :fixed="true" v-if="is_home"></t-navbar> -->
      <G-Loading v-if="modeling" />
      <div class="home-logo">
        <div class="logo">gututu.ai</div>
        <div class="text">
                      <div class="slogan">
                        AI驱动的金融智能<br>
                        精准数据 · 可靠分析 · 智慧决策<br></div>
         </div>
      </div>
      
      <div style="padding: 0px 20px;">
        <div class="input-container">
            <input type="text" v-model="prompt" class="input-field" placeholder="想查什么股票，尽管问我" enterkeyhint="send" @keyup.enter="magic">
            <button class="btn btn-primary send-button" @click="magic" :disabled="modeling || prompt== ''" >
              <icon-font name="send" />
            </button> 
            
              <!-- <textarea  enterkeyhint="send"></textarea> -->
              
          </div>

          <div class="suggest-panel"> 
            <div style="font-weight:bold; padding-left:10px;margin-bottom: 10px;font-size:18px;">精选查询</div>
            <ul class="suggestions">
              <li v-for="(page, index) in homeStore.pages" :key="'prompt-' + index" class="suggestion-link" @click="showPage(page.page_uuid)">
                  <span class="number">{{ index + 1 }} </span> {{ page.title }}
              </li>
            </ul>
          </div>
      </div>

      <t-dialog
    v-model:visible="isShowDialog"
    :content="message"
    confirm-btn="知道了"
    :close-on-overlay-click="true"
  >
    </t-dialog>

    <t-dialog
      v-model:visible="upgradeDialog"
      close-on-overlay-click
      :content="message"
      cancel-btn="取消"
      :confirm-btn="{ content: '马上升级', danger: 'success' }"
      @confirm="onConfirm"
    >
    </t-dialog>


      <div class="bottom-bar"> </div>
      <GuTabBar selectedIndex="0"></GuTabBar>
    </div>
    
   </template>
   
   <script>
     /* eslint-disable */
     import 'bootstrap/dist/css/bootstrap.min.css';
   import api from '../common/api'; 
   import { Toast } from 'tdesign-mobile-vue';
   import { mapGetters, mapActions } from 'vuex';
   import { IconFont } from 'tdesign-icons-vue-next';
   import GuTabBar from '../components/GuTabBar.vue';
   import { isWechatEnv, isMiniApp } from '@/common/tools'
   import { usePageStore } from '@/stores/PageStore';
   import { useHomeStore } from '@/stores/HomeStore';
   import { useCanvasStore } from '@/stores/CanvasStore';
   import { getOpenId } from '@/common/wechat'
   import { useShareStore } from '@/stores/ShareStore';

   export default {
     name: 'Home',
     components: {
       IconFont,
       GuTabBar
     },
     computed: {
      ...mapGetters(['account', 'isLogin'])
     },
     data: () =>{
          return {
              pageStore: usePageStore(),
              title: '我的',
               // 页面类型 首页列表，我的收藏，最近查看
              is_loading: false,
              connect_failure: false,
              is_home: true, // 默认首页
              modeling: false,
              miniapp: false,
              prompt: '',
              isShowDialog: false,
              upgradeDialog: false,
              message: '',
              homeStore: useHomeStore(),
              canvasStore: useCanvasStore(),
              shareStore: useShareStore(),
          }
     },
     created() {
      this.miniapp = isMiniApp()  // 判断是否在小程序内

      this.homeStore.fetchPages()

      if (this.miniapp) {
        // 小程序分享出去的卡片，点击来自动跳转
        this.miniAppRedirect()
    
        // 根据code获得用户微信小程序的openid
        this.getMiniAppOpenID()
      } 

      if (isWechatEnv()) {
        getOpenId().then(res => {
          // console.log(res, '公众号openid')
        })
      }

      this.$UMTrackPV({page: 'mine', type: 'mine'});
      // 检查是否已登录
      // if (this.isLogin) {

      // } else {
        // const code = this.$route.query.code
        // if (code) {
        //   // 小程序自动登录
        //   this.loginByWechat(code)
        // }
      // }
      
     },

     methods: {
      ...mapActions(['loginSuccess', 'logoutSuccess']),
      inputPrompt(word) {
        this.prompt = word
      }, 
      getWehatCode() {
        
      },

      // 小程序自动登录
      loginByWechat(code) {
        var params = { code: code }
        api.post('/wechat_login', params).then(response => {
          const result = response.data
          if (result.status == 'success') {
            Toast.success('小程序免密登录成功')
            // 设置cookies，有效期7天
            document.cookie = `jwt=${result.token};path=/;max-age=${60*60*24*7};`;
            this.loginSuccess(result.account)
          }
        })
      },

      getMiniAppOpenID() {
        if (!localStorage.getItem("miniapp_openid")) {
          const code = this.$route.query.code

          if (!code) {
            return
          }
          var params = { code: code }
          api.post('/get_miniapp_openid', params).then(response => {
            const result = response.data
            if (result.status == 'success') {
              localStorage.setItem("miniapp_openid", result.openid ? result.openid : ""); 
            }
          })
        } else {
          var openid = localStorage.getItem("miniapp_openid");
          console.log('小程序当前用户openid: ' + openid)
        }
      },
      miniAppRedirect() {
        // 从详情页返回后，就不跳转
        if (this.shareStore.isView)
          return;

        // 小程序分享出去的卡片，点击来自动跳转
        if (this.$route.query.page_id)
        {
          this.$router.push('/pages/' + this.$route.query.page_id + '?from=share');
        }
        if (this.$route.query.user_id)
        {
          this.$router.push('/users/' + this.$route.query.user_id + '?from=share');
        }
        if (this.$route.query.stock)
        {
          this.$router.push('/stock/' + this.$route.query.stock + '?from=share');
        }
      },
      
      showPage(page_id) {
        this.$router.push('/pages/' + page_id);
      },

      dialog(content) {
        this.message = content
        this.isShowDialog = true
      },
      checkPrompt() {
        if (this.prompt == '') {
          this.dialog('请输入查询提示语')
          return false
        }

        if (this.prompt.length <= 4) {
          this.dialog('请输入不少于5个字的查询提示语')
          return false
        }

        if (this.prompt.length >= 150) {
          this.dialog('提示语不能超过150个字')
          return false
        }
        return true
      },
      magic(){
        if (!this.checkPrompt()) {
          return;
        }

        // 免费用户只能查询3次
        this.canvasStore.create(this.prompt)
        this.$router.push('/canvas');

        return;

        // if (result.type =='upgrade') {
        //   this.upgradeDialog = true
        // } else {
        //   this.isShowDialog = true
        // }

      },
      // 确认升级会员，跳转到升级页面
      onConfirm() {
        this.$router.push('/vip');
      }
     }
   };
   </script>
   <style scoped>

   .tag-list {
      text-align: left;
      margin-top: 5px;
   }
   .t-tag {
    margin-bottom:5px;
   }
   .magic {
      height: 60px;
      display: flex;
      position: relative;
   }

   .magic-word {
    border: #ECECEC 1px solid ;
    padding: 2px;
    border-radius: 5px;
   }

   .send-button {
    top: 10px;
    right: 10px;
    position: absolute;
    z-index: 1;
    border-radius: 8px;
    /* color: var(--msh-chat-send-button-color);
    background-color: var(--msh-chat-send-button-bg); */
    border-radius: 12px;
    height: 36px;
    width: 40px;
   }

   .home-logo {
      margin-top: 20px;
      width: 100%;
      height: 140px;
      text-align: center;
   }

   .text .desc {
      color: #777;
   }


   .home-form  textarea{
      flex: 1; 
      float:left; 
      height: 55px;
      margin-right: 5px;
      padding: 5px;
      padding-right: 50px;
      border-radius: 10px;
   }

   .home-form textarea:focus {
    border-color: #0d6efd; /* 设置聚焦时的边框颜色 */
    outline: none; /* 可选：移除默认的聚焦轮廓线 */
  }
  
   .search-bar {
  
      padding: 10px 5px;
      width: 100%;
      height: 60px;
   }
  
   .page .name {
      font-size: 16px;
      width:100%;
      clear:both;
   }
   .page .icon-btn {
     width: 100px;
     margin-top: 10px;
     margin-right:10px;
     float:left;
     height: 28px;
     
   }
   .page .icon-btn span {
      padding-left: 5px;
   }
  
   .failure {
    height: 300px;
    padding-top: 80px;
   }
  
  .page-list {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    height: calc(100vh - 96px - 56px - env(safe-area-inset-top));
    overflow-y: auto;
  }

  .logo {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      color: #0d6efd;
      margin-bottom: 10px;
      font-family: sans-serif, Arial, Helvetica;
    }

    .slogan {
      text-align: center;
      font-size: 18px;
      color: #6a7278;
      margin-bottom: 20px;
      line-height: 1.4;
    }

    .input-container {
      margin-bottom: 15px;
      position: relative;
    }

    .input-field {
      width: 100%;
      padding: 15px;
      font-size: 16px;
      border: 1px solid #e5e5e5;
      border-radius: 25px;
      box-sizing: border-box;
      transition: border-color 0.3s;
      outline: none;
      border-color: #0d6efd;
    }


    .suggest-panel {
      width:100%;
      background:#fff; 
      border-radius:15px;
      text-align: left; 
      padding:15px; 
      padding-bottom: 5px;
      /* border: solid #c9c9c9 1px; */
    }

  .suggestions {
      list-style-type: none;
      padding: 0;
      margin-left:10px;
    }
    .suggestion-link {
      padding: 8px;
      padding-left:0px;
      display: block;
      border-bottom: solid #ECECEC 1px;
      color: #1e2026;
      text-decoration: none;
      transition: background-color 0.3s;
      font-size: 14px;
      text-align: left;
    }

    .suggestion-link:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }

    /* .suggestion-link:hover {
      background-color: #e5e5e5;
    } */

    .number {
      display: block; float:left; width:20px;font-family: Arial, Helvetica, sans-serif;
    }

  /* 定义一个关键帧动画 */
  @keyframes scale-up {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }

  /* 应用动画到元素 */
  .scale-up {
    animation: scale-up 0.3s ease-in-out forwards; /* 动画持续时间、缓动函数和动画结束后的状态 */
  }

   </style>