<template>
    <span v-if="price_change" class="danger">{{ value }}</span>
    <span v-else-if="market_cap">{{ value }}</span>
    <span v-else>{{ value }}-</span>
  </template>
  
  <script>
  /* eslint-disable */
  export default {
    name: 'ListValue',
    props: {
      column_name: String,
      value: String
    },
    data() {
      return {
        data: {
            // price_change: column_name == '涨幅',
            // market_cap: column_name.includes('市值')
        }
      }
    }
  };
  </script>