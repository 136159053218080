// HotPageStore.js
import api from '@/common/api'; 
import { defineStore } from 'pinia';
import dayjs from 'dayjs';

export const useHomeStore = defineStore('home', {
    state: () => ({
      pages: [],
      loadAt: null
    }),
    
    actions: {
        fetchPages() {
            return new Promise((resolve) => {
                // 用dayjs 判断 loadAt是否已经超过5分钟
                if (this.loadAt && dayjs(this.loadAt).add(5,'minute').isAfter(dayjs())) {
                    resolve(this.pages);
                    return
                }

                api.get('/pages/random').then(response => {
                    const result = response.data
                    if (result.status =='success') {
                        this.loadAt = dayjs()
                        this.pages = result.data
                        resolve(this.pages);
                    } 
                })

            });
        },


  }
});