<template>
    <div class="h5-page with-nav-bar">
      <t-navbar :title="user.name" :fixed="true" left-arrow @left-click="goBack"></t-navbar>
      <G-Loading v-if="isLoading" />

      <div class="user-header">
        <img :src="user.avatar" alt="logo" width="80px" />
        <div class="">
            {{ user.name }}<br>
            {{ user.profile }}
         </div>
      </div>


     <t-tabs default-value="0"  @change="onChange">
        <t-tab-panel value="0" label="画板"/>
        <t-tab-panel value="1" label="赞过" />
      </t-tabs>
  
      <div id="app" class="container page-bg">
        <div class="failure" v-if="connect_failure">
          <t-result theme="warning" title="网络异常" description="请检查手机网络信号是否正常" class="space" />
        </div>
      
        
          <div class="infinite-scroll-list page-list">
            <div class="page infinite-scroll-item" v-for="page in pages" :key="page.page_uuid" @click="showPage(page.page_uuid)">
                <div class="name">
                    <span>{{page.title}}</span>
                </div>
                <div class="row">
                  <div class="icon-btn"> 
                    <img src="/images/like.png" width="18" height="20" style="margin-top:-2px;"> <span>{{ page.like_count }}</span> 
                  </div>
                  <div class="icon-btn"> 
                    <img src="/images/collect.png" width="20" style="margin-top:-2px;">  <span>{{ page.collect_count }}</span>   </div>
                  <div class="icon-btn"> <icon-font name="share-1" /><span>{{ page.share_count }}</span>  </div>
                </div>
            </div>
          </div>
      
          <EmptyTips :word="empty_tips" v-if="pages.length == 0 && finished && !isLoading" />
          
          <YiInfiniteLoading
            :loading="isLoading"
            :finished="finished"
            offset="100%"
            :hideLoading="true"
            @loadMore="loadMore"
          >
          <t-loading theme="dots" size="40px" v-if="isLoading"/>

            <!-- <span class="tips" v-if="isLoading">正在加载</span> -->
            <!-- <span class="tips" v-if="finished && pages.length > 0">没有更多</span> -->
          </YiInfiniteLoading>
            
          <div class="clear" >
            <!-- <button @click="clear" >clear</button>
            <button v-if="!isLoading" @click="loadMore" >load more</button> -->
          </div>
            
          
      </div>
      
    </div>
   </template>
   
   <script>
     /* eslint-disable */
     import 'bootstrap/dist/css/bootstrap.min.css';
   import api from '../common/api'; 
   import { Toast } from 'tdesign-mobile-vue';
   import { mapGetters, mapActions } from 'vuex';
   import { IconFont } from 'tdesign-icons-vue-next';
   import YiInfiniteLoading from 'yi-infinite-loading-vue3'
   import GuTabBar from '../components/GuTabBar.vue';
   import EmptyTips from '../components/EmptyTips.vue'
   import { useShareStore } from '@/stores/ShareStore';

   export default {
     name: 'Home',
     components: {
       IconFont,
       GuTabBar,
       YiInfiniteLoading
     },
     computed: {
      ...mapGetters(['account', 'isLogin'])
     },
     data: () =>{
          return {
              title: '股图图',
              type: 'home', // 页面类型 首页列表，我的收藏，最近查看
              user_id: 0,
              user: {},
              pages: [],
              connect_failure: false,
              isLoading: false,
              finished: false,
              page_number: 1,
              empty_tips: '',
              shareStore: useShareStore()
          }
     },
     created() {
        this.sharePageViewed()
        this.user_id = this.$route.params.user_id
        this.loadUserInfo()
        this.update_type()
     },
     methods: {
      goBack() {
        this.$router.back();
      },
      sharePageViewed() {
        // 标记分享页面已经浏览过
        const from = this.$route.query.from
        if (from == 'share') {
          this.shareStore.viewed()
        }
      },
      loadUserInfo() {
        api.get('/users/' + this.user_id).then(
            response => {
                const result = response.data
                if (result.status == 'success') {
                    this.user = result.data
                }
            }
        )
      },
      getLoadUrl() {
        var url = ''
        switch (this.type) {
          case 'like':
            url =  '/pages/user/' + this.user_id + '/like'
            break;
          case 'user':
            url =  '/pages/user/' + this.user_id
            break;
          default:
            url =  '/pages/user/' + this.user_id
        }
        return url + '?page_num='  + this.page_number
      },
      update_type(type = 'user') {
        this.pages = []
        this.page_number = 1
        this.finished = false
        if (type == 'like') {
          this.type = 'like'
          this.title = '赞过的'
          this.empty_tips = '还没有赞过别人的画板'
        } else {
          this.type = 'user'
          this.empty_tips = '还没有创建画板'
        }
        this.loadData()
      },
      loadData() {
        this.isLoading = true
        var url = this.getLoadUrl()
        api.get(url)
           .then(response => {
            console.log(url);

            const result = response.data
            if (result.status == 'success') {
              const data = result.data;
              for (let item of data)
                this.pages.push(item);
  
              if (data.length < process.env.VUE_APP_PAGE_SIZE)
                this.finished = true
            }

            this.isLoading = false      
         })
         .catch(error => {
           this.connect_failure = true
           this.isLoading = false
           console.log('Fetching data failed', error);
         });
      },
      // 重新加载
      // clear() {
      //   this.pages = []
      //   this.page_number = 1
      //   this.loadData()
      // },
      loadMore() {
        if (this.isLoading || this.finished) {
          return
        }
        console.log('loadMore')
        this.isLoading = true
        this.page_number +=1;
        this.loadData()
      },
      onChange(event, label) 
      {
        // 处理事件
        if (label == '画板') { 
          this.update_type('user')
        } else {
          this.update_type('like')
        } 
      },
      showPage(page_id) {
        this.$router.push('/pages/' + page_id);
      },
      like(page_id) {
        
        api.post('/pages/' + page_id + '/like')
         .then(response => {
           const result = response.data.status;
           if (result == 'success') {
             Toast('点赞成功')
           } else {
             Toast('点赞失败')
           }
         })
  
      },
      comment(page_id) {
        Toast('评论')
      },
      share(page_id) {
        Toast('分享')
      }
     }
   };
   </script>
   <style scoped>
   .user-header {
      margin-top: 20px;
      width: 100%;
      height: 130px;
      text-align: center;
   }

   .home-form {
      width: 100%;
      height: 100px;
      padding: 15px;
      text-align: center;
   }
  
   .search-bar {
  
      padding: 10px 5px;
      width: 100%;
      height: 60px;
   }
  
   .page .name {
      font-size: 16px;
      width:100%;
      clear:both;
   }
   .page .icon-btn {
     width: 100px;
     margin-top: 10px;
     margin-right:10px;
     float:left;
     height: 30px;
     
   }
   .page .icon-btn span {
      padding-left: 5px;
   }
  
   .failure {
    height: 300px;
    padding-top: 80px;
   }
  
  .page-list {
    padding-top: 15px;
  }
   .empty-tip {
      background: #FFF;
      padding: 100px 0px;
   }
   .tips {
    text-align: center;
   }
   </style>