<template>
  <Transition name="slide-fade">
    <div :id="`card-${card_id}`" class="card" v-if="!isDel">
      <h3>{{ title }}</h3>
      <component :is="cardType" :columns="columns" :chartData="data" />

      <div class="card-footer" v-if="hide_btn == 0 && !sharing">
        <span @click="copy" v-if="isLogin"><icon-font name="chart-add" /> 复制</span>  
        <span @click="report"> <icon-font name="thumb-down" /> 报错 </span> 
        <span @click="share"> <icon-font name="share" /> 分享 </span> 
        <span @click="modify" v-if="isAccount(user_id)"><icon-font name="edit" /> 修改</span> 
        <span @click="delCard" v-if="isAccount(user_id)"><icon-font name="delete" /> 删除</span>
      </div>
    </div>

  </Transition>

  </template>
  
  <script>
  /* eslint-disable */
  import api from '../common/api'; 
  import { IconFont } from 'tdesign-icons-vue-next';
  import { Toast } from 'tdesign-mobile-vue';
  import { mapGetters } from 'vuex';
  import html2canvas from 'html2canvas';

  import ListCard from './ListCard.vue';
  import LineCard from './LineCard.vue';
  import PieCard from './PieCard.vue';
  import BarCard from './BarCard.vue';

  export default {
    name: 'CardComponent',
    components: {
        ListCard,
        BarCard,
        LineCard,
        PieCard,
        IconFont
    },
    props: {
      user_id: Number,
      card_id: Number,
      title: String,
      type: String,
      columns: Array,
      data: Array,
      hide_btn: {
        type: Number, 
        default: 0 
      }
    },
    computed: {
      ...mapGetters(['account', 'isAccount', 'isLogin']),
      cardType() {
        switch (this.type) {
          case 'list':
            return 'ListCard';
          case 'pie':
            return 'PieCard';
          case 'line':
            return 'LineCard';
          case 'bar':
            return 'BarCard';
          default:
            return 'div';
        }
      }
    },
    data() {
      return {
        isDel: false,
        sharing: false
      }
    },
    methods: {
      delCard() {
        this.isDel = true;

        api.post('/cards/delete', {
          card_id: this.card_id,
        }).then(response => {
          const result = response.data;
          if (result.status =='success') {
            Toast.success('删除卡片成功')
          } else {
            Toast.error('删除卡片失败')
            setTimeout(() => {
              this.isDel = false;
            }, 800);
          }
        })
        .catch(error => {
          Toast.error('删除卡片失败')
          this.isDel = false;
          console.error(error);
        });
      },
      modify() {
        this.$router.push('/cards/' + this.card_id + '/edit');
      },
      copy() {
        this.$emit('copy-card', this.card_id);
      },
      report() {
        this.$emit('report-error', this.card_id);
      },
      share() {
        const element = document.querySelector('#card-' + this.card_id);
        this.sharing = true;

        this.$nextTick(() => {
          // html2canvas(element).then((canvas) => {
          //   const imgData = canvas.toDataURL('image/png');
          //   const link = document.createElement('a');
          //   link.download = 'card.png';
          //   link.href = imgData;
          //   link.click();
          //   this.sharing = false;
          // })

        })

       
      }
    }
  };
  </script>
  
  <style scoped>
  .card {
    border: 1px solid #ccc;
    padding: 15px;
    margin: 10px;
  }
  .card-footer span{
    margin-right: 15px;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(150px);
    opacity: 0;
  }
  </style>