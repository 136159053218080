<template>
    <div class="empty-tips">
        <div class="empty-image">
            <img src="https://m.gututu.net/images/empty.png" alt="empty" />
        </div>
        <div class="empty-word">
            {{ word }}
        </div>
    </div>
  
</template>
    
<script>
/* eslint-disable */
export default {
    name: 'EmptyTips',
    components: {
    },
    props: {
    word: String,
    },
    data() {
    return {
    }
    },
};
</script>
<style scoped>
 .empty-tips{
    text-align: center;
    /* background: #FAFAFA; */
    height: 500px;
    padding-top: 80px;
}

.empty-image {
    margin-top: 10px;
}

.empty-word {
    padding-top: 20px;
    color:#9E9E9E;  
}
</style>