<template>
  <div class="h5-page" style="overflow-y: auto; " >

    <!-- <t-navbar title="画板" :fixed="true" left-arrow @left-click="goBack">
      <template #right>
          <t-icon name="ellipsis" size="24px" @click="showSheet = true"/>
      </template>
    </t-navbar> -->
    <t-action-sheet v-model="showSheet" :items="action_items" @selected="handleSelected"  />
    <!-- @cancel="handleCancel" -->

    <div v-if="guideTip" style="z-index:100; position:fixed; right: 30px; top: 20px;background:#fff;padding:10px; border-radius: 5px;">⬆️点击右上角分享</div>

  <!-- <div class="h5-page with-nav-bar"> -->
    <div id="gututu-page">
    <!-- <div class="bottom-bar"></div> -->
      <div class="page-header" @click="updatePageTitle" v-if="isAccount(page.user_uuid)">
        <img src="https://m.gututu.net/images/back2.png" 
        style="position: relative; top: 10px; left: 10px;z-index: 2; width:30px;" 
        v-if="is_share == 0"
        @click.stop="goBack(); "/>

        <div v-if="!edit_title" @click.stop="editPageTitle"  class="page-title">
          {{page.title}} <icon-font name="edit" v-if="!is_share"/><span @click.stop="showSheet = true" v-if="!is_share">更多</span>
        </div>
        <div class="page-title" v-else>
          <input id="page-input" v-model="page.title" style="width:100%" 
          enterkeyhint="done"
          @click.stop="" 
          @keydown.enter="updatePageTitle"/>
        </div>
      </div>

      <div class="page-header" v-else>
        <img src="https://m.gututu.net/images/back2.png" style="position: relative; top: 10px; left: 10px;z-index: 2; width:30px;" 
        v-if="is_share == 0"
        @click.stop="goBack(); "/>

        <div v-if="!edit_title" class="page-title">
          {{page.title}}
        </div>
      </div>

      <!-- <div  >
        <div>长按保存</div>
      </div> -->

      <t-popup v-model="shareImage" placement="center" style="width:85%;height: 70%;">
        <div class="image-to-save">
          <img style="width:90%" :src="imageUrl" ref="imageToSave" @touchstart="handleTouchStart" @touchend="handleTouchEnd" @touchcancel="handleTouchEnd" />
        </div>
        <div style="height:50px;width: 100%; text-align: center;padding-top:15px;"> 长按截图保存</div>
      </t-popup>


      <EmptyTips word="没有卡片了，点击右下角添加一张吧" v-if="cardStore.isEmpty(page_id)"/>

      <div id="card-list" class="card-list">
        <transition-group name="player" tag="div">

          <!-- <CardComponent
            v-for="item in cards"
            :key="item.title"
            :user_id="item.user_id"
            :card_id="item.card_id"
            :title="item.title"
            :type="item.type"
            :columns="item.columns"
            :data="item.data"
            :hide_btn="is_share"
            @copy-card="copy"
            @report-error="report"
            class="player-item"
          /> -->
          <CardById
            v-for="item in cardStore.pages[page_id]"
            :key="item.card_uuid"
            :user_id="item.user_uuid"
            :card_id="item.card_uuid"
            :hide_btn="is_share"
            @del-card="del"
            @copy-card="copy"
            @share-card="shareCard"
            @report-error="report"
            @adjust-card="adjust"
            class="player-item"
          />  
      </transition-group>
    
      </div>

      <Banner v-if="is_share == 1"/>
      <Disclaimer :date="dataUpdateDate" v-if="cardStore.hasCard(page_id) && is_share == 0"/>
    <!-- end of gututu-->
    </div>

    <t-fab :icon="addIcon" text="添加卡片" @click="showAddCard" 
    v-if="false && !addCard && isAccount(page.user_uuid)" 
    style="right:16px; bottom: calc(82px + env(safe-area-inset-bottom)); "/>

    <div class="bottom-popup">
      <t-popup v-model="reportError" placement="bottom" style="padding: 15px">
        <div class="popup-desc">
           <div style="float:left; margin-right:10px;">请输入报错原因</div> 
        </div>
        <div style="display: flex;">
            <textarea v-model="error_desc" style="height:120px; flex: 1; float:left; margin-right: 10px;"></textarea>
            <button class="btn btn-primary" style="height:120px; width: 50px;float:right;" @click="submitReport()"  :disabled="modeling">提交
            </button>
        </div>
        <div style="height:26px;"> </div>
      </t-popup>
    </div>

    <div class="bottom-popup">
      <t-popup v-model="addCard" placement="bottom" style="padding: 15px">
        <div v-if="modeling" class="popup-desc">
           <div style="float:left; margin-right:10px;">大模型正在生成图表</div> <t-loading class="bottom-loading" theme="dots" size="20px" style="float:left;"/>
        </div>
        <div v-else class="popup-desc">
           <div style="float:left; margin-right:10px;">请输入卡片描述</div> 
        </div>
        <div style="display: flex;">
            <textarea v-model="prompt" style="height:120px; flex: 1; float:left; margin-right: 10px;"></textarea>
            <button class="btn btn-primary" style="height:120px; width: 50px;float:right; " @click="renderCard"  :disabled="modeling">生成
            </button>
        </div>
        <div style="height:26px;"> </div>
      </t-popup>
    </div>


    <G-Loading v-if="is_loading || cardStore.loading"/>

    <div class="bottom-bar"> </div>
  

  <!-- 底部操作栏 -->
  <div class="footer-bar" >
      <div class="info" v-if="page.user" @click="showUser(page.user_uuid)">
          <t-image class="avatar" :src="page.user.avatar" shape="round" />
          <div class="avatar-text" v-if="page.user">
            {{page.user.name}}  
            <!-- ❤️ 🔖 -->
          </div>
      </div>

      <div class="action" v-if="page.title != undefined">
        <div class="icon" @click="like()"> 
          <transition name="like-animation">
            <img v-if="isLiked" src="/images/liked.png" alt="Liked" width="28">
          </transition>

          <!-- <transition name="like-animation"> -->
            <img v-if="!isLiked" src="/images/like.png" alt="Unlike" width="28">
          <!-- </transition> -->
          
          <span style="padding-left: 2px;">{{ page.like_count }}</span>
          <!-- <icon-font name="thumb-up" /> -->
        </div>

        <div class="icon" @click="collect()">
          <transition name="like-animation">
            <img v-if="isCollected" src="/images/collected.png" width="28"> 
          </transition>

          <img v-if="!isCollected" src="/images/collect.png" width="28"> 

          <span style="padding-left: 4px;">{{ page.collect_count }}</span>
          <!-- <icon-font name="bookmark-add" /> -->
        </div>
        <div class="icon" @click="sharePage()"> <icon-font name="share-1" /></div>
      </div>
    </div>
</div>
</template>

<script>
  /* eslint-disable */
import { h, onBeforeRouteLeave, onBeforeMount } from 'vue';
import { AddIcon } from 'tdesign-icons-vue-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../common/api'; 
import { Toast } from 'tdesign-mobile-vue';
import { mapGetters } from 'vuex';
import CardComponent from '../components/Card.vue';
import CardById from '../components/CardById.vue';
import html2canvas from 'html2canvas';
import { ActionSheet, ActionSheetItem } from 'tdesign-mobile-vue';
import { IconFont } from 'tdesign-icons-vue-next';
import dayjs from 'dayjs';
import { isMiniApp } from '../common/tools'
import EmptyTips from '@/components/EmptyTips.vue';
import Banner from '@/components/Banner.vue';
import Disclaimer from '@/components/Disclaimer.vue';
import { useCardStore } from '@/stores/CardStore';
import { usePageStore } from '@/stores/PageStore';
import { useShareStore } from '@/stores/ShareStore';

const getTradeDate = () => {
  const now = dayjs();
  const dayOfWeek = now.day();
  var date = now;

  if (dayOfWeek == 6) {
    date = now.subtract(1, 'day');
  } else if (dayOfWeek == 0) {
    date = now.subtract(2, 'day')
  } else if (dayOfWeek == 1 && now.hour() + now.minute() / 60 < 9.5) {
    date = now.subtract(3, 'day')
  } else if (dayOfWeek > 1 && dayOfWeek < 6 && now.hour() + now.minute() / 60 < 9.5) {
    // 每天早上9:30前返回昨天的日期
    date = now.subtract(1, 'day');
  }
  return date.format('YYYY-MM-DD')
}

const ITEM_SHARE    = '分享画板';
const ITEM_DELETE   = '删除画板';
const ITEM_PUBLIC   = '设为公开';
const ITEM_PRIVATE  = '设为私密';

export default {
  name: 'App',
  components: {
    CardComponent,
    CardById,
    IconFont,
    EmptyTips,
    Banner,
    Disclaimer
  },
  computed: {
    ...mapGetters(['account', 'isAccount', 'isLogin'])
   },
  data() {
    return {
      addIcon: h(AddIcon, { size: '24px' }),
      page_id: '',
      page: {},
      edit_title: false,
      isLiked: false,
      isCollected: false,
      is_loading: false,
      is_share: 0, // 是否正在分享
      modeling: false,
      showSheet: false,
      addCard: false,
      reportCardId: 0,
      reportError: false, // 卡片报错
      action_items: ['分享'],
      cards:[],
      temp_card: {},
      imageUrl: '', //分享的截图
      longPressTimer: null,
      dataUpdateDate: '',
      longDisclaimer: false, // 底部长声明
      shareImage: false,
      guideTip: false,
      cardStore: useCardStore(),
      shareStore: useShareStore()
    }
  },
  created() {
    this.sharePageViewed()

    this.page_id = this.$route.params.page_id;
    this.loadPageInfo()

    this.cardStore.fetchCards(this.page_id).then(data => {
      
    })
    this.viewPage()
    this.dataUpdateDate = getTradeDate()  // 获得数据更新截止日期

    setTimeout(() => {
      var list = document.getElementsByClassName('h5-page')[0]
      const position = this.cardStore.restoreScrollPosition()
      if (position > 0) {
        list.scrollTop = position
      }
    }, 150)
  },
  beforeRouteLeave(to, from, next) {
    var list = document.getElementsByClassName('h5-page')[0]
    this.cardStore.saveScrollPosition(list.scrollTop)
    next();
  },
  methods: {
    goBack() {
      const from = this.$route.query.from
      if (from == 'login') {
        this.$router.go(-3);  // 跳过登录页和原来的页面
      } else if (from == 'share') {
        this.$router.replace({ path: '/' });
      } else {
        this.$router.back();
      }
    },
    more() {
      this.showSheet = true
    },
    sharePageViewed() {
      // 标记分享页面已经浏览过
      const from = this.$route.query.from
      if (from == 'share') {
        this.shareStore.viewed()
      }
    },
    checkLogin() {
      if (!this.isLogin) {
        this.$router.push('/login?from=' + this.$route.path);
        return false;
      }
      return true;
    },
    closePageTitle() {
      this.edit_title = false
    },
    editPageTitle() {
      this.edit_title = true
      this.$nextTick(() => {  
        document.querySelector('#page-input').focus();
      })
    },
    updatePageTitle() {
      api.post(`/pages/${this.page_id}/update`, {title: this.page.title})
      .then(response => {
          const result = response.data.status
          if (result =='success') {
            this.edit_title = false
            // 更新缓存
            const pageStore = usePageStore();
            pageStore.clearPageData();
          } else {
            Toast.error('接口出错，标题修改失败')
          }
        })
    },
    del(card_id) {
      if (!this.checkLogin()) {
        return;
      }
      this.cardStore.removeCard(this.page_id, card_id)
    },
    copy(card_id) {
      if (!this.checkLogin()) {
        return;
      }
    
      // 处理非自己的卡片的copy
      // this.cardStore.copyCard(this.page_id, card_id)

      api.post('/cards/' + card_id + '/copy')
      .then(response => {
        const result = response.data.status
        if (result == 'success') {
          this.cardStore.fetchCards(this.page_id, true)
          Toast.success('复制成功')
        }
      })
    },
    report(card_id) {
      this.$UMRecord('reportError', {card_id: card_id});

      this.reportCardId = card_id
      this.reportError = true;
      this.error_desc = ''
    },
    adjust() {
      this.$router.push('/page/' + this.page_id + '/move');
    },
    submitReport() {
      var url = '/cards/' + this.reportCardId + '/error'
      api.post(url, {
          desc: this.error_desc,
          }).then(response => {
              const result = response.data
              if (result.status == 'success') {
                Toast.success('提交成功')
                this.reportError = false
              } else {
                Toast.error('提交失败')
              }
          }).finally(() => {
            
          })
    },
    like() {
      if (!this.checkLogin()) {
        return;
      }
      this.$UMRecord('likePage', {page_id: this.page_id});

      api.post('/pages/' + this.page_id + '/like')
       .then(response => {
         const result = response.data.status;
         if (result == 'success') {
            this.isLiked = !this.isLiked
            if (this.isLiked) {
              Toast('点赞成功')
              this.page.like_count += 1
            } else {
              this.page.like_count -= 1
            }
              
         } else {
            // Toast('点赞失败')
         }
       })
    },
    // 收藏
    collect() {
      if (!this.checkLogin()) {
        return;
      }
      this.$UMRecord('collectPage', {page_id: this.page_id});
      
      api.post('/pages/' + this.page_id + '/collect').then(response => {
        const result = response.data.status
        if (result == 'success') {
          this.isCollected = !this.isCollected
          if (this.isCollected) {
            Toast.success('收藏成功');
            this.page.collect_count += 1
          }
          else 
            this.page.collect_count -= 1
        } else {
            Toast.error('收藏失败');
        }
      })
      .catch(error => {
          Toast.error('收藏失败');
          console.error(error);
      });
    },
    sharePage() {
      this.$UMRecord('sharePage', {page_id: this.page_id});

      if (isMiniApp()) {
        this.guideTip = true;
        setTimeout(() => {
          this.guideTip = false
        }, 3000);
      } else {
        this.is_share = 1
        this.is_loading = true
        setTimeout(this.takeScreenshot, 400);

      } 

      
      return
      
      // Toast('分享到微信')
      // 微信官方文档 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#111
      // 分享到微信

              // const params = {
        //   type: 'share',
        //   content: {
        //     title: '股图图分享到朋友圈测试',
        //     path: 'page/home',
        //     imageUrl: 'https://m.gututu.net/images/icon.png'
        //   }
        // }

        if (this.cards.length > 0) {
          if (isMiniApp()) {
            this.miniAppShare(this.page.title, this.cards[0].card_id)
          } else {

            this.is_share = 1
            this.is_loading = true
            setTimeout(this.takeScreenshot, 400);

            // Toast('网页分享')
          }
        } else {
          Toast('没有卡片可以分享')
        }

      // wxShare()
      // .config({
      //   appId: process.env.VUE_APP_WECHAT_APP_ID,
      //   timestamp: dayjs().unix(),
      //   nonceStr: 'gututu.ai',
      //   signature: 'page-detail'
      // })
      // .share({
      //   title: this.page.title,
      //   desc: 'by 股图图',
      //   link: 'https://m.gututu.net/pages/' + this.page.page_id,
      //   imgUrl: 'https://m.gututu.net/images/icon.png'
      // });
      // return;

      // window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        // window.wx.updateAppMessageShareData({ 
        //   title: this.page.title, // 分享标题
        //   desc: 'by 股图图', // 分享描述
        //   link: 'https://m.gututu.net/pages/' + this.page.page_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //   imgUrl: 'https://m.gututu.net/images/icon.png', // 分享图标
        //   success: function () {
        //     // 设置成功
        //     Toast('分享成功')
        //   }
        // })
      // }); 
      return;

      // 分享到朋友圈
      // wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
      //   wx.updateTimelineShareData({ 
      //     title: '', // 分享标题
      //     link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //     imgUrl: '', // 分享图标
      //     success: function () {
      //       // 设置成功
      //     }
      //   })
      // });


      // if (navigator.share) {
      //   navigator.share({
      //     title: '分享图片', // 可选的标题
      //     text: '这是我要分享的图片', // 可选的文本
      //     url: 'https://pics6.baidu.com/feed/5882b2b7d0a20cf4384e8a28d34ac938adaf9938.jpeg@f_auto?token=a9868655c3ed571395a221debf42da5f' // 图片的 URL
      //   })
      //   .then(() => console.log('分享成功'))
      //   .catch((error) => console.log('分享失败:', error));
      // } else {
      //   console.log('浏览器不支持 navigator.share');
      // }
      // return

    },

    shareCard(title, data) {
      // 小程序分享
      if (isMiniApp()) {
        this.miniAppShare(title, data)
      } else {
        this.shareImage = true
        this.imageUrl = data;
        Toast('网页分享')
      }
    },
    miniAppShare(title, card_id) {
      const url = `/page/share?page_id=${this.page_id}&card_id=${card_id}&title=${title}`
      window.wx.miniProgram.navigateTo({
        url: url,
        fail: function (res) {
          // Toast('跳转失败')
        },
        success: function () {
          // Toast('跳转成功')
        }
      })
    },
    // 右上角菜单操作
    handleSelected(selected, selectedIndex) {
      ActionSheet.close();
      if (selected == ITEM_SHARE ) {
        this.sharePage()
      } else if (selected == ITEM_PUBLIC || selected == ITEM_PRIVATE ) {
        let state = selected == '设为公开' ? 1 : 0;
        this.setPublicState(state)
      } else if (selected == ITEM_DELETE) {
        this.delPage()
      }
    },
    viewPage() {
      api.post('/pages/' + this.page_id + '/view').then(response => {
          // console.log('result' + JSON.stringify(response.data.status))
      })
    },
    loadPageInfo() {
      api.get('/pages/' + this.page_id)
        .then(response => {
          const result = response.data
          if (result.status == 'success')
          {
            this.page = result.data;
            if (this.isLogin) {
              this.isLiked = result.is_liked
              this.isCollected = result.is_collected
              this.updateActionItems()
            }
          }
        }).catch(error => {
          console.error('Fetching data failed', error);
        })
    },
    updateActionItems() {
      if (this.isAccount(this.page.user_uuid)) {
          const item_name = this.page.is_public == 0 ? ITEM_PUBLIC : ITEM_PRIVATE;
          this.action_items = [ITEM_SHARE, item_name, ITEM_DELETE]
      }
    },
    // 修改页面属性 0 私密 1 公开 
    setPublicState(open) {
      let params = {public: open}
      api.post('/pages/' + this.page_id + '/setting', params)
     .then(response => {
          const result = response.data.status;
          if (result =='success') {
            this.page.is_public = open
            Toast.success('修改成功')
            this.updateActionItems()
          } else {
            Toast.error('修改失败')
          }
        })

    },
    delPage() {
        api.post('/pages/delete', {
          page_id: this.page_id,
        }).then(response => {
          Toast.success('删除成功');
          
          const pageStore = usePageStore();
          pageStore.removePage(this.page_id)

          setTimeout(() => {
            this.goBack()
          }, 800)
        })
        .catch(error => {
          Toast.error('删除失败');
          console.error(error);
        });
    },
    showUser(user_id) {
      this.$UMRecord('showUser', {user_id: user_id});
      this.$router.push('/users/' + user_id);
    },
    takeScreenshot() {
      const element = document.querySelector('#gututu-page');
      html2canvas(element, {scrollX: 0, scrollY: -window.scrollY}).then((canvas) => {
        try{
          const imgData = canvas.toDataURL('image/png');
          this.shareImage = true
          this.imageUrl = imgData;
          this.is_share = 0
        } catch (error) {
          console.log(error)
        }
        // const link = document.createElement('a');
        // link.download = 'screenshot.png';
        // link.href = imgData;
        this.is_loading = false
        // link.click();
      })
    },

    handleTouchStart(e) {
      if (!isMiniApp()) {
        // 非小程序环境，才需要处理长按事件
        const longPressDuration = 800; // 长按时间阈值，单位毫秒
        this.longPressTimer = setTimeout(() => {
            // 长按操作，触发保存图片
            this.saveImage();
        }, longPressDuration);
      } 
    },

    handleTouchMove (e) {
    },
    handleTouchEnd() {
      if (!isMiniApp()) {
        clearTimeout(this.longPressTimer);
      } 
    },
    saveImage() {
      const link = document.createElement('a');
      link.href = this.imageUrl;
      link.download = 'gututu_ai_chart.png'; // 指定下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    showAddCard() {
      this.prompt = ''
      this.addCard = true
    },
    renderCard() {
      if (!this.isLogin) {
        this.$router.push('/login?from=' + this.$route.path);
        return
      }

      var _self = this
      _self.modeling = true

      var url = '/magic?page_id=' + this.page_id
      api.post(url, {
          prompt: this.prompt,
          }).then(response => {
              const result = response.data
              if (result.status == 'success') {
                const new_card = result.data
                Toast.success('生成成功')
                _self.addCard = false
                _self.cardStore.addCard(_self.page_id, new_card)
              } else {
                Toast(result.message)
              }
          }).finally(() => {
            _self.modeling = false
          })
    },
  }
};
</script>

<style scoped>

.like-animation-enter-active {
  transition: all 0.25s ease-out;
}
.like-animation-enter-from{
  opacity: 0;
  transform: translateY(30px);
}

.like-animation-leave-active
.like-animation-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.card-list {
  padding: 10px 3px;
}
.card-form {
  padding: 10px 25px;
}
.page-bg {
    background: #EDEDED;
}

.player-enter,
.player-leave-to {
  opacity: 0;
}

.player-enter {
  transform: translateY(30%);
}

.player-leave-to {
  transform: translateX(300%);
}

.player-leave-active {
  position: absolute;
}

.page-header {
  padding: 10px;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}


/* 渐变色 https://www.creative-tim.com/twcomponents/gradient-generator/ */
/* https://www.grabient.com/ */

.grabient-style {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.grabient-style2 {
  background-color: #FFE53B;
  background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);
}

.grabient-style3 {


}


#gututu-page {
  height: fit-content;
}

.page-title {
  color: #FFF;
  font-weight: bold;
  font-size: 18px;
  margin-top:70px;
  margin-left:15px;
  margin-bottom: 10px;
}

.footer-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(56px + env(safe-area-inset-bottom));
    border-top: #ECECEC solid 1px;
    background-color: #FFF;
    color: white;
    display: flex;
    align-items: center;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
    z-index: 1000; 
    padding-bottom: constant(safe-area-inset-bottom); 
    padding-bottom: env(safe-area-inset-bottom); 
  }

  .footer-bar .info {
    width: 50%;
  }
  .footer-bar .action {
    width: 50%;
    float: right;
    display: flex;
    justify-content: flex-end;
    color: #000;
  }

  .avatar {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 20px;
    float: left;
  }

  .avatar-text {
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
    float: left;
    color: #000;
  }

  .icon {
    font-size: 24px;
    cursor: pointer;
    float: left;
    width: 90px;
  }
  .icon span {
    font-size: 18px;
  }

  .bottom-popup {
    padding-bottom: constant(safe-area-inset-bottom); 
    padding-bottom: env(safe-area-inset-bottom); 
  }

  .popup-desc {
    clear:both; width: 100%;display: flex; padding-bottom: 8px;
  }

  .image-to-save {
    width: 90%; /* 容器宽度 */
    height: 80%; 
    overflow: hidden; /* 隐藏超出部分 */
    position: fixed;
    top: 60px;
    left: 30px;
    z-index: 9999;
    padding: 15px;
    background: #fff;
  }

  .image-to-save img {
    position: absolute;
    left: 0; /* 从左边开始 */
    top: 0;
    width: auto; /* 自动宽度 */
    height: auto; /* 高度填满容器 */
    object-fit: cover;
  }

  /* 只显示图片的前30% */
  .image-to-save::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
  }

 
</style>