// HotPageStore.js
import api from '@/common/api'; 
import { defineStore } from 'pinia';
import dayjs from 'dayjs';

export const useProfileStore = defineStore('profile', {
    state: () => ({
      vip_level: 0,
      vip_expire: null,
      loadAt: null
    }),
    
    actions: {
        fetchVip(reload=false) {
            return new Promise((resolve) => {
                // 用dayjs 判断 loadAt是否已经超过5分钟
                if (!reload && this.loadAt && dayjs(this.loadAt).add(5,'minute').isAfter(dayjs())) {
                    resolve(this.pages);
                    return
                }

                api.get('/profile').then(response => {
                    const result = response.data
                    if (result.status == 'success') {
                        this.loadAt = dayjs()
                        this.vip_level = result.data.vip_level
                        this.vip_expire = result.data.vip_expire
                        resolve(this.vip_level);
                    } 
                })

            });
        },
        reset() {
            this.vip_level = 0
            this.vip_expire = null
            this.loadAt = null
        }

  }
});