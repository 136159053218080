<template>
  <div class="h5-page">
    <t-navbar title="服务条款" :fixed="true" left-arrow @left-click="goBack">
  </t-navbar>

    <div id="app" class="container">
      <p>最近更新: {{ update_date }}<br><br>

本使用条款（“协议”）是您与 {{company_name}}（“我们”、“我们的”或“我们”）之间的合同，我们希望您在使用我们的应用程序之前了解您和我们的权利（ “应用程序”或“软件”）及相关服务（“服务”）。 本协议不适用于任何第三方网站、服务或应用程序，即使它们可以通过我们的服务访问。一旦您访问、查看或使用该应用程序，我们即视为您同意本协议中所述的条款和条件。 如果您不同意本协议，请立即停止使用本App。您必须年满 17 岁才能使用服务。如果您未满18岁，您必须获得父母或法定监护人的许可才能使用服务。如果您代表其他人或实体使用服务，您必须有权代表他们接受本协议的这些条款。我们有权修改本协议有关软件更新的条款。您可以在本软件中查看本协议的修改版本。您理解并同意，如果您在这些条款变更之日后继续使用本软件，我们将把您的使用视为接受并遵守更新后的条款。
</p>

<p>
  <b>1.一般使用限制</b><br><br>

1.1 我们授予您个人、可撤销、不可转让且非排他性的应用程序使用许可。 除非另有明确书面同意，您可以在您的智能移动终端设备（包括但不限于平板电脑、智能手机（“终端设备”））上安装、使用、显示或运行本应用程序或使用本服务，用于非商业目的。
<br><br>
1.2 您不得在下列情况下使用本应用程序：
<br><br>
1.2.1 您不得以任何方式从本应用程序创作衍生作品或对本应用程序的全部或部分进行商业利用；
<br><br>
1.2.2 您只能将本应用程序用于合法目的；
<br><br>
1.2.3 除非应用程序功能允许，否则您不得使用、出售、修改或分发应用程序；
<br><br>
1.2.4 您不得进行任何危害计算机网络安全的行为，包括但不限于：使用未经授权的数据或未经授权访问服务器/账户； 未经允许进入公共计算机网络或者他人计算机系统删除、修改、添加存储的信息； 试图搜索、扫描、测试本应用系统、网络泄密或其他破坏网络安全的行为； 试图干扰、破坏应用程序系统的正常运行，故意传播恶意软件、病毒，破坏正常的互联网信息服务； 伪造 TCP/IP 包的（部分）名称。
<br><br>
1.3 您使用本应用程序的方式不得导致本应用程序（或与本应用程序连接的服务器和互联网）损坏、暂停、超载或质量下降，或者可能影响所提供服务的方式。
<br><br>
1.4 您承诺不向任何其他人复制、授予分许可、共享或出售本应用程序或服务。您对由此产生的任何费用和开支负全部责任。
<br><br>
1.5 我们不允许将我们的服务用于以下用途：
<br><br>
1.5.1 非法活动：我们禁止使用我们的服务进行非法活动。
<br><br>
1.5.2 儿童性虐待材料或任何剥削或伤害儿童的内容。
<br><br>
1.5.3 产生仇恨、骚扰或暴力内容：表达、煽动或宣扬基于身份的仇恨的内容；意图骚扰、威胁或欺凌个人的内容；或宣扬或美化暴力或歌颂苦难的内容 或侮辱他人。
<br><br>
1.5.4 生成恶意软件：尝试生成旨在破坏、损坏或未经授权访问计算机系统的代码的内容。
<br><br>
1.5.5 具有高人身伤害风险的活动，包括：武器开发；军事和战争；能源、交通和水等关键基础设施的管理或运营；或宣扬、鼓励或描绘自残行为的内容， 例如自杀、自残和饮食失调。
<br><br>
1.5.6 具有高经济损害风险的活动，包括：多层次营销；赌博；发薪日贷款；或 自动确定信贷、就业、教育机构或公共援助服务的资格。
<br><br>
1.5.7 欺诈或欺骗性活动，包括：诈骗；协同不真实行为；剽窃；学术不诚实；Astroturfing，例如虚假草根支持或虚假评论生成；虚假信息；垃圾邮件；或伪药品。
<br><br>
1.5.8 成人内容、成人产业和交友应用，包括：旨在引起性兴奋的内容，例如性活动的描述，或宣传性服务（不包括性教育和健康）的内容； 色情聊天；色情内容。
<br><br>
1.5.9 政治竞选或游说，通过： 生成大量竞选材料； 生成针对特定人群的个性化活动材料；建立对话或互动系统，例如聊天机器人，提供有关活动的信息或参与政治宣传或游说； 构建用于政治竞选或游说目的的产品
<br><br>
1.5.10 侵犯他人隐私的行为，包括：未经个人同意跟踪或监控个人；对个人进行面部识别； 根据受保护的特征对个人进行分类； 使用生物识别技术进行识别或评估； 非法收集或披露个人身份信息或教育、财务或其他受保护记录。 如果您使用服务来处理个人数据，您必须提供合法的充分隐私声明并获得处理此类数据所需的同意，并且您向我们声明您正在根据适用法律处理此类数据。
<br><br>
1.5.11 从事未经授权的法律执业，或在没有合格人员审查信息的情况下提供量身定制的法律建议。我们的服务未经过微调以提供法律建议。 您不应依赖我们的服务作为法律建议的唯一来源。
<br><br>
1.5.12 在没有合格人员审查信息的情况下提供量身定制的财务建议。我们的服务并未针对提供财务建议进行微调。 您不应依赖我们的服务作为财务建议的唯一来源。
<br><br>
1.5.13 告诉某人他们有或没有某种健康状况，或提供如何治愈或治疗健康状况的说明。 我们的服务未经过微调以提供医疗信息。 您切勿使用我们的服务为严重的医疗状况提供诊断或治疗服务。我们的软件不应用于分类或管理需要立即关注的危及生命的问题。
<br><br>
1.5.14 高风险政府决策，包括：执法和刑事司法； 移民和庇护。
<br><br>
1.6 我们保留未明确授予您的所有权利。 如果您违反任何这些限制，该许可可能会自动终止，并且我们可以随时自行决定终止该许可。
</p>

<p>
<b>2.隐私政策</b><br><br>

我们尊重每个用户的隐私和个人信息，并将根据适用的法律，特别是数据保护特别法，采取必要措施保护您的隐私和个人信息，并在收集、处理、传输和存储您的个人信息时遵守该法 。 请您务必仔细阅读我们的《隐私政策》，其中规定了我们收集、使用您个人信息的方式等。

</p>

<p>
<b>3.知识产权</b><br><br>

3.1 本软件由我们独立开发，您承认并同意我们拥有或已获得权利人的充分授权，享有该软件的任何及全部合法权益，包括该软件和服务所涉及的任何知识产权 。
<br><br>
3.2 应用程序中的信息包括但不限于我们拥有、控制或许可并受版权保护的文学作品、文字、软件、照片、广告中的所有内容、我们向您提供的商业信息等 、专利、商标以及所有其他相关法律法规。 仅当您获得我们的授权后，您才可以使用这些信息； 未经授权不得复制、修改或编译这些内容或创建相关衍生作品。
<br><br>
3.3 除非您与我们另行达成书面协议，本协议并不授权您使用本软件的名称、商标、服务标志、标识、软件域名或任何其他具有鲜明品牌特征的标志。
<br><br>
3.4 如果您认为您的作品被以构成侵犯版权的方式复制，或者您的知识产权受到其他侵犯，请通过下面显示的电子邮件与我们联系。
</p>

<p>
  <b>4.内容</b><br><br>

4.1 您的内容。 您可以向服务提供输入（“输入”），并接收服务根据输入生成和返回的输出（“输出”）。 输入和输出统称为“内容”。 在双方之间，在适用法律允许的范围内，您拥有所有输入。 在您遵守本协议条款的前提下，我们特此将 Output 的所有权利、所有权和权益转让给您。 这意味着，如果您遵守本协议的这些条款，您可以将内容用于任何目的，包括商业目的，例如销售或出版。 为了对您的输入提供反馈或回应，您理解并同意我们需要与我们的人工智能技术合作伙伴共享您的输入。我们和我们的人工智能技术合作伙伴可以使用内容来提供和维护服务，遵守适用的法律，并且 执行协议条款。
<br><br>
4.2 内容的相似性。 由于机器学习的性质，输出在用户之间可能不是唯一的，并且服务可能会为我们或第三方生成相同或相似的输出。 例如，您可以向模型提供诸如“天空是什么颜色？”之类的输入。 并接收诸如“天空是蓝色的”之类的输出。 其他用户也可能会提出类似的问题并收到相同的答复。 由其他用户请求和为其他用户生成的响应不被视为您的内容。
<br><br>
4.3 准确性。 人工智能和机器学习是快速发展的研究领域。 我们和我们的人工智能技术合作伙伴不断努力改进我们的服务，使其更加准确、可靠、安全和有益。 鉴于机器学习的概率性质，在某些情况下使用我们的服务可能会导致不正确的输出，无法准确反映真实的人物、地点或事实。 您应该根据您的用例评估任何输出的准确性，包括对输出进行人工审核。

</p>

<p>
  <b>5.付费服务</b><br><br>

5.1 本软件提供的部分服务需要付费使用（“付费服务”）。 全额支付费用后，您将在订阅期内获得付费服务。 将来，该软件可能包含更多付费服务。 如果您在后续付费服务更新期间已经订阅了付费服务，您将可以在订阅期内免费使用后续付费服务，但前提是： 1）我们无需额外付费； 2) 您根据需要更新软件以使用进一步的付费服务。
<br><br>
5.2 对于我们提供的任何付费服务，我们接受通过购买前指定的当前付款方式付款，其中可能包括 Apple Payments 以及我们不时向您提供的任何其他付款方式。 您同意遵守任何相关服务条款或其他法律协议（无论是与 Apple 还是与第三方），这些条款或其他法律协议管辖您对特定付款处理方式的使用。
<br><br>
5.3 我们可以根据实际需要修改我们的付款政策。 一些免费服务将来可能会变成付费服务。 如果您未按时足额缴纳费用，当我们开始收费时，您将无法再使用付费服务。 如果出现降价或促销，我们将不会提供价格保护或退还您的付款。 请注意，只要您购买了付费服务，无论您是否使用该服务，我们都不会退还您的费用。

</p>

<p>
  <b>6.赔偿</b><br><br>

您使我们免受其他用户或任何其他第三方（包括公共机构）因您违反本协议和我们的隐私政策而向我们提出的所有索赔，包括损害索赔。 您应承担因您侵犯其他用户或第三方的权利而给我们带来的所有合理费用，包括法律辩护所产生的合理费用。 我们的所有进一步权利以及损害索赔均不受影响。

</p>

<p>
  <b>7.责任限制及免责声明</b><br><br>

7.1 您理解并接受您对您的内容承担全部责任，包括确保其不违反任何适用法律或本协议的条款。 我们不认可任何内容或其中表达的任何意见或建议，并明确否认与此类内容相关的任何及所有责任。
<br><br>
7.2 您承认并同意，本应用及本服务可能存在因不可抗力、移动通信终端病毒或黑客攻击、系统不稳定、用户物理位置、手机关机等原因导致服务中断、无法响应用户请求等潜在风险。 以及其他有关技术、电信线路的原因。 在任何情况下，我们均不对上述任何风险承担责任。
<br><br>
7.3 对于因电信线路故障、技术问题、网络、移动通信终端故障、系统不稳定及其他不可抗力给用户造成的损失，我们不承担责任。
<br><br>
7.4 鉴于业务发展和调整，我们保留随时修改或终止服务而不需事先通知用户的权利，且我们在执行该权利时不对用户及任何其他第三方承担责任。
<br><br>
7.5 未经我们正式发布或授权的服务及其衍生作品均属非法。 用户下载、安装、使用本软件可能会带来意想不到的风险。 我们不承担由此产生的任何法律责任、问题。
<br><br>
7.6 您在适用法律的最大范围内同意，我们拥有本协议中未列出的其他免责权利。
<br><br>
7.7 我们或我们的任何附属公司或许可方均不对任何间接、偶然、特殊、后果性或惩戒性损害承担责任，包括利润、商誉、使用或数据损失或其他损失，即使我们已被告知 发生此类损害的可能性。本节中的限制仅适用于适用法律允许的最大范围。

</p>

<p>
<b>8.应用程序的更新、停止、暂停和终止</b><br><br>

8.1 您有权随时停止使用本应用程序。
<br><br>
8.2 本应用程序可能会不时更新。 您可以自行决定是否接受更新。 如果您不接受更新，我们不保证为您提供最新更新的服务，由此产生的任何损失由您自行承担。
<br><br>
8.3 我们保留随时根据实际情况修改、中断、暂停或终止提供本应用程序的权利，具体情况包括但不限于单个用户可获得的存储空间、上传内容的性质和数量以及 其他数据或用户持续访问应用程序和服务的能力等。
<br><br>
8.4 您同意，出现以下情况时，我们可以不经事先通知而暂停或终止与本应用相关的服务：1）您违反了这些条款；2）我们这样做须遵守适用法律或主管当局的要求；3）或者我们需要这样做的其他条件。
<br><br>
8.5 服务终止后，我们在符合法律法规的前提下保留彻底删除您的所有信息的权利，并且我们不再对您承担任何义务。

</p>

<p>
<b>9.其他</b><br><br>

9.1 您同意，如果我们不行使或执行任何合法权利或规定，这不会被视为对我们权利的正式放弃，我们有权继续行使或执行该权利或规定。
<br><br>
9.2 未经对方书面授权，用户不得将本条款所授予的权利转让或转移，也不得将指定的责任和义务转让给他人。
<br><br>
9.3 本条款的效力和解释适用中华人民共和国法律。 如果本条款中的任何规定与中华人民共和国法律相抵触，应根据相关法律重新解释该规定。 这些条款的无效或重新解释不会影响其余条款的有效性和执行。 我们和用户均同意通过协商解决因本条款而产生的问题。 若协商不成，任何一方均可按照本条款规定提交仲裁，则该争议最终由中国国际经济贸易仲裁委员会华南分会仲裁解决。 按照申请仲裁时有效的中国国际经济贸易仲裁委员会仲裁规则进行。 仲裁裁决为终局裁决，对双方均具有约束力。
<br><br>
9.4 我们保留对本条款的最终解释权。
</p>

<p>
<b>10.联系我们</b><br><br>

如果您对本服务或本协议有任何疑问，或者您想获取当前文档的先前版本，请随时通过 {{email}} 与我们联系。
</p>
    </div>

  </div>
 </template>
 
 <script>
   /* eslint-disable */
 import axios from 'axios';
 import 'bootstrap/dist/css/bootstrap.min.css';
 export default {
   name: 'Agreement',
   components: {
     
   },
   data() {
    return {
      update_date: '8月10日, 2024年',
      company_name: 'Gututu',
      email: 'hi@gututu.com',
    }
   },
   created() {

   },
   methods: {
      goBack() {
        this.$router.back();
      },
   }
 };
 </script>
 <style scoped>

   .container { padding: 0px 25px;}
    p {margin-top: 50px;}
 </style>