<template>
  <div class="h5-page">
        <t-navbar title="问题反馈" :fixed="true" left-arrow @left-click="goBack">
    </t-navbar>

    <t-loading theme="dots" size="40px" v-if="isLoading"/>
  
    <t-form
    ref="form"
    :data="formData"
    :rules="rules"
    reset-type="empty"
    show-error-message
    label-align="top"
    :disabled="disabled"
    scroll-to-first-error="auto"
    @reset="onReset"
    @submit="onSubmit"
  >
    <t-form-item label="您的邮箱" name="name" help="方便与您联系">
      <t-input v-model="formData.account" borderless placeholder="输入您的邮箱"></t-input>
    </t-form-item>

    <t-form-item label="反馈内容" name="content">
      <t-textarea
        v-model="formData.content"
        class="textarea"
        indicator
        :maxlength="100"
        placeholder="请输入反馈内容，bug、使用问题、产品建议、商务合作等"
      ></t-textarea>
    </t-form-item>

    <t-form-item label="上传截图" name="photo">
      <t-upload
        :default-files="formData.avatar"
        multiple
        :max="8"
        :action="action"
        :on-fail="onFail"
        :on-progress="onProgress"
        :on-change="onChangeUpload"
        :on-preview="onPreview"
        :on-success="onSuccess"
        :on-remove="onRemove"
        :on-select-change="onSelectChange"
      >
      </t-upload>
    </t-form-item>

    <div class="button-group">
      <t-button theme="primary" variant="light" type="submit" size="large">提交</t-button>
    </div>

    </t-form>

  </div>
 </template>
 
 <script>
   /* eslint-disable */
import api from '../../common/api'; 
import { Toast } from 'tdesign-mobile-vue';
 
 export default {
   name: 'Feedback',
   components: {
     
   },
   data() {
     return {
       isLoading: false,
       formData: {
         account: '',
         content: '',
         avatar: [],
       }
     };
   },
   created() {

     
   },
   methods: {
      goBack() {
        this.$router.back();
      },
      onSubmit() {
        api.post('/feedback', {}).then(response => {
          Toast.success('提交成功')
        }).catch(error => {
          console.log(error);
        }).finally(() => {

        })

      }
   },
 };
 </script>
 <style scoped>
.footer {
  margin-top: 50px;
}
.link {
  /* text-decoration: underline; */
  color: #1890ff;
  cursor: pointer;
}

</style>