// useAuth.js
import { ref, watchEffect } from 'vue';

export function useAuth() {
  const isLogin = ref(false);
  const cookieList = ref(document.cookie.split('; '));

  // 检查cookie中的token
//   function checkLoginStatus() {
//     const token = getCookie('jwt');
//     isLogin.value = !!token;
//   }

  // 监听isLogin的变化，以便在token变化时更新状态
  watchEffect(() => {
    const token = cookieList.value.find(row => row.startsWith('jwt='))?.split('=')[1]
    isLogin.value = !!token;
    // checkLoginStatus();
  });

  // 假设的getCookie函数
//   function getCookie(name) {
//     let cookieArray = document.cookie.split('; ');
//     let cookie = cookieArray.find(row => row.startsWith(name));
//     return cookie ? cookie.split('=')[1] : null;
//   }

  return {
    isLogin
  };
}