import axios from 'axios';

// 创建一个 axios 实例
const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000, // 请求超时时间
});

// 请求拦截器
api.interceptors.request.use(
  config => {
    // 从 cookie 获取 JWT Token
    const token = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1];
    if (token != undefined) {
      // console.log('token:' + token)
      // 将 Token 添加到请求头 Authorization 字段
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 导出 http 实例
export default api;

