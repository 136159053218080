// ShareStore.js
import { defineStore } from 'pinia';

export const useShareStore = defineStore('share', {
    state: () => ({
      isView: false,
    }),
    
    actions: {
        // 添加或更新卡片信息
        viewed() {
            this.isView = true;
        },
    }
});