// HotPageStore.js
import api from '@/common/api'; 
import { defineStore } from 'pinia';

export const useCardDataStore = defineStore('card', {
    state: () => ({
      cards: [],
      isLoaded: false,
      finished: false,
      loading: false,
      error: null,
      scrollPosition: 0
    }),
    
    actions: {
        // 添加或更新卡片信息
        addOrUpdateCard(cardId, data) {
            this.cards[cardId] = data;
        },
        // 获取卡片信息
        getCard(cardId) {
            if (this.cards[cardId]) {
                return this.cards[cardId];
            } else {
                return {};
            }
        },
        // 删除卡片信息
        removeCard(cardId) {
            delete this.cards[cardId];
        },
        fetchData( cardId ) {
            return new Promise((resolve) => {
                if (this.cards[cardId]) {
                    console.log('卡片Data已获取')
                    resolve(this.cards[cardId])
                    return
                }

                this.loading = true
                
                api.get('/data?card_id=' + cardId)
                .then(response => {
                  this.loading = false
                  const result = response.data
                  if (result.status == 'success') {
                    const card = result.data[0]
                    this.addOrUpdateCard(cardId, card)
                    resolve(card)
                  }
                }).catch(error => {
                    this.error = error
                    resolve([]);
                })
            });
        }
  }
});