<template>
    <div class="h5-page" style="overflow-y: auto;" >
  
      <!-- <t-navbar title="画板" :fixed="true" left-arrow @left-click="goBack">
        <template #right>
            <t-icon name="ellipsis" size="24px" @click="showSheet = true"/>
        </template>
      </t-navbar> -->
      <t-action-sheet v-model="showSheet" :items="action_items" @selected="handleSelected"  />
      <!-- @cancel="handleCancel" -->
  
    <!-- <div class="h5-page with-nav-bar"> -->
      <div id="gututu-page">
      <!-- <div class="bottom-bar"></div> -->
        <div class="page-header" @click="updatePageTitle">
          <img src="https://m.gututu.net/images/back2.png" 
          style="position: relative; top: 10px; left: 10px;z-index: 2; width:30px;" 
          v-if="is_share == 0"
          @click.stop="goBack(); "/>
  
          <div v-if="!edit_title" @click.stop="editPageTitle"  class="page-title">
            {{page.title || '画板标题'}} <icon-font name="edit" v-if="!is_share"/><span @click.stop="showSheet = true" v-if="!is_share">更多</span>
          </div>
          <div class="page-title" v-else>
            <input id="page-input" v-model="page.title" style="width:100%" 
            enterkeyhint="done"
            @click.stop="" 
            @keydown.enter="updatePageTitle"/>
          </div>
        </div>
  
        
        <t-popup v-model="shareImage" placement="center" style="width:85%;height: 70%;">
          <div class="image-to-save">
            <img style="width:90%" :src="imageUrl" ref="imageToSave" @touchstart="handleTouchStart" @touchend="handleTouchEnd" @touchcancel="handleTouchEnd" />
          </div>
          <div style="height:50px;width: 100%; text-align: center;padding-top:15px;"> 长按截图保存</div>
        </t-popup>
  
  
        <!-- <EmptyTips word="没有卡片了" v-if="cardStore.isEmpty(page_id)"/> -->
  
        <div id="card-list" class="card-list">
          <transition-group name="player" tag="div">
            <CardById
              v-for="item in cardStore.pages[page_id]"
              :key="item.card_uuid"
              :user_id="item.user_uuid"
              :card_id="item.card_uuid"
              :hide_btn="is_share"
              @del-card="del"
              @copy-card="copy"
              @share-card="shareCard"
              @report-error="report"
              @adjust-card="adjust"
              class="player-item"
            />  
        </transition-group>
      
        </div>

        <Disclaimer :date="dataUpdateDate" v-if="cardStore.hasCard(page_id) && !modeling"/>

        <div style="width:100%; padding:15px; height:60px;" v-if="firstLoad || modeling">
          <div style="float:left;margin-right:10px;">{{ status }}</div> 
          <t-loading class="bottom-loading" theme="dots" size="20px" style="float:left;margin-top:3px;"/>
        </div>

        <Banner v-if="is_share == 1"/>
  
      </div>
  
      <div class="bottom-popup">
        <t-popup v-model="reportError" placement="bottom" style="padding: 15px">
          <div class="popup-desc">
             <div style="float:left; margin-right:10px;">请输入报错原因</div> 
          </div>
          <div style="display: flex;">
              <textarea v-model="error_desc" style="height:120px; flex: 1; float:left; margin-right: 10px;"></textarea>
              <button class="btn btn-primary" style="height:120px; width: 50px;float:right;" @click="submitReport()"  :disabled="modeling">提交
              </button>
          </div>
          <div style="height:26px;"> </div>
        </t-popup>
      </div>
  
      <G-Loading v-if="is_loading || cardStore.loading"/>
  
      <div class="bottom-bar"> </div>
    
        <!-- 底部操作栏 -->
      <div class="footer-bar" >
          
        <div v-if="!isVip" style="width:100%;position: absolute;
        clear:both; top: -12px; z-index: 1001; text-align:right;
            ">
            <div style="width: 210px; margin-right: 25px;
            padding:0px 10px; text-align:center; font-size:14px;
             color:#666; background:#e3f9e9; float:right;">
              {{user_type}}查询次数 <b>{{ prompt_limit }}</b> 次
            </div>
        </div>

          <div class="input-container">
              <input id="prompt-input" type="text" v-model="prompt" class="input-field" placeholder="想查什么股票，尽管问我" enterkeyhint="send" @keyup.enter="renderCard">
              <button class="btn btn-primary send-button" @click="renderCard" :disabled="modeling" >
                <icon-font name="send" />
              </button> 
          </div>
      </div>

      <t-dialog
    v-model:visible="isShowDialog"
    :content="message"
    confirm-btn="知道了"
    :close-on-overlay-click="true"
  >
    </t-dialog>

    <t-dialog
      v-model:visible="upgradeDialog"
      close-on-overlay-click
      :content="message"
      cancel-btn="取消"
      :confirm-btn="{ content: (isLogin ? '马上开通' : '登录'), danger: 'success' }"
      @confirm="onConfirm"
    >
    </t-dialog>

  </div>
  </template>
  
  <script>
    /* eslint-disable */
  import { h, onBeforeRouteLeave, onBeforeMount } from 'vue';
  import { AddIcon } from 'tdesign-icons-vue-next';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import api from '../common/api'; 
  import { Toast } from 'tdesign-mobile-vue';
  import { mapGetters } from 'vuex';
  import CardComponent from '../components/Card.vue';
  import CardById from '../components/CardById.vue';
  import html2canvas from 'html2canvas';
  import { ActionSheet, ActionSheetItem } from 'tdesign-mobile-vue';
  import { IconFont } from 'tdesign-icons-vue-next';
  import dayjs from 'dayjs';
  import { isMiniApp } from '../common/tools'
  import EmptyTips from '@/components/EmptyTips.vue';
  import Banner from '@/components/Banner.vue';
  import Disclaimer from '@/components/Disclaimer.vue';
  import { useCardStore } from '@/stores/CardStore';
  import { usePageStore } from '@/stores/PageStore';
  import { useShareStore } from '@/stores/ShareStore';
  import { useCanvasStore } from '@/stores/CanvasStore';
  
  const getTradeDate = () => {
    const now = dayjs();
    const dayOfWeek = now.day();
    var date = now;
  
    if (dayOfWeek == 6) {
      date = now.subtract(1, 'day');
    } else if (dayOfWeek == 0) {
      date = now.subtract(2, 'day')
    } else if (dayOfWeek == 1 && now.hour() + now.minute() / 60 < 9.5) {
      date = now.subtract(3, 'day')
    } else if (dayOfWeek > 1 && dayOfWeek < 6 && now.hour() + now.minute() / 60 < 9.5) {
      // 每天早上9:30前返回昨天的日期
      date = now.subtract(1, 'day');
    }
    return date.format('YYYY-MM-DD')
  }
  
  const ITEM_SHARE    = '分享画板';
  const ITEM_DELETE   = '删除画板';
  const ITEM_PUBLIC   = '设为公开';
  const ITEM_PRIVATE  = '设为私密';
  
  export default {
    name: 'App',
    components: {
      CardComponent,
      CardById,
      IconFont,
      EmptyTips,
      Banner,
      Disclaimer
    },
    computed: {
      ...mapGetters(['account', 'isAccount', 'isLogin', 'isVip'])
     },
    data() {
      return {
        addIcon: h(AddIcon, { size: '24px' }),
        page_id: '0',
        page: {},
        edit_title: false,
        isLiked: false,
        isCollected: false,
        is_loading: false,
        is_share: 0, // 是否正在分享
        modeling: false,
        showSheet: false,
        addCard: false,
        reportCardId: 0,
        reportError: false, // 卡片报错
        action_items: ['分享'],
        cards:[],
        temp_card: {},
        imageUrl: 'https://m.gututu.net/images/icon.png', //分享的截图
        longPressTimer: null,
        dataUpdateDate: '',
        longDisclaimer: false, // 底部长声明
        shareImage: false,
        firstLoad: true,
        status: '正在思考中',
        cardStore: useCardStore(),
        shareStore: useShareStore(),
        canvasStore: useCanvasStore(),
        prompt_limit: 3,
        isShowDialog: false,
        upgradeDialog: false,
        user_type: '未登录用户',
        message: ''
      }
    },
    created() {
      this.getPromptLimit()
      
      if (this.isLogin) {
        this.user_type = '免费用户'
      }

      if (this.canvasStore.rendering) {
        console.log('gututu', 'createPage')
        this.createPage()
      } else {
        if (this.canvasStore.canvas_page_id) {
          this.firstLoad = false
          this.page_id = this.canvasStore.canvas_page_id
          this.cardStore.fetchCards(this.page_id)
          this.loadPageInfo()
          this.scrollToLast()   // 恢复到原来的滚动位置
        }
      }
      
      this.dataUpdateDate = getTradeDate()  // 获得数据更新截止日期
    },
    beforeRouteLeave(to, from, next) {
      var list = document.getElementsByClassName('h5-page')[0]
      this.canvasStore.saveScrollPosition(list.scrollTop)
      next();
    },
    methods: {
      goBack() {
        const from = this.$route.query.from
        if (from == 'login') {
          this.$router.go(-3);  // 跳过登录页和原来的页面
        } else {
          this.$router.back();
        }
      },
      onConfirm() {
        if (this.isLogin) {
          this.$router.push('/vip');
        } else {
          this.$router.push('/login');
        }
      },
      more() {
        this.showSheet = true
      },
      startRunning() {
        this.status = '正在思考中'

        setTimeout(() => {
          this.status = "正在查询中";
        }, 2000);

        setTimeout(() => {
          this.status = "正在生成图表";
        }, 4000);
      },
      createPage() {
        this.startRunning()
        this.prompt = this.canvasStore.currentPrompt()

        var params = {title: "画板标题"}
        if (!this.isLogin) {
          params['visitor_id'] = this.canvasStore.getVisitorId()
        }
        console.log('gututu', params)
        this.hideKeyboard()
        api.post('/pages/create', params).then(response => {
          const result = response.data
          if (result.status == 'success') {
            this.page_id = result.page_id // 新创建画板 的uuid
            this.canvasStore.setPageId(this.page_id)
            this.renderCard()
            this.loadPageInfo()
          } else {
            Toast('生成画板失败，请返回首页重试')
            this.canvasStore.done()
          }
        }).finally(() => {
          this.firstLoad = false
        })
        
      },
      hideKeyboard() {
        var input = document.querySelector('#prompt-input');
        if (input)
          input.blur();
      },
      getPromptLimit() {
        if (this.isVip)
          return;

        api.post('/prompt_limit', {visitor_id: this.canvasStore.getVisitorId()})
            .then(response => {
                const result = response.data
                if (result.status =='success') {
                  this.prompt_limit = result.limit
                }
            })
      },
      checkLogin() {
        if (!this.isLogin) {
          this.$router.push('/login?from=' + this.$route.path);
          return false;
        }
        return true;
      },
      closePageTitle() {
        this.edit_title = false
      },
      editPageTitle() {
        this.edit_title = true
        this.$nextTick(() => {  
          document.querySelector('#page-input').focus();
        })
      },
      updatePageTitle() {
        api.post(`/pages/${this.page_id}/update`, {title: this.page.title})
        .then(response => {
            const result = response.data.status
            if (result =='success') {
              this.edit_title = false
              // 更新缓存
              const pageStore = usePageStore();
              pageStore.clearPageData();
            } else {
              Toast.error('接口出错，标题修改失败')
            }
          })
      },
      del(card_id) {
        if (!this.checkLogin()) {
          return;
        }
        this.cardStore.removeCard(this.page_id, card_id)
      },
      copy(card_id) {
        if (!this.checkLogin()) {
          return;
        }
      
        // 处理非自己的卡片的copy
        // this.cardStore.copyCard(this.page_id, card_id)
  
        api.post('/cards/' + card_id + '/copy')
        .then(response => {
          const result = response.data.status
          if (result == 'success') {
            this.cardStore.fetchCards(this.page_id, true)
            Toast.success('复制成功')
          }
        })
      },
      report(card_id) {
        this.$UMRecord('reportError', {card_id: card_id});
  
        this.reportCardId = card_id
        this.reportError = true;
        this.error_desc = ''
      },
      adjust() {
        this.$router.push('/page/' + this.page_id + '/move');
      },
      submitReport() {
        var url = '/cards/' + this.reportCardId + '/error'
        api.post(url, {
            desc: this.error_desc,
            }).then(response => {
                const result = response.data
                if (result.status == 'success') {
                  Toast.success('提交成功')
                  this.reportError = false
                } else {
                  Toast.error('提交失败')
                }
            }).finally(() => {
              
            })
      },

      sharePage() {
        this.$UMRecord('sharePage', {page_id: this.page_id});
  
        this.is_share = 1
        this.is_loading = true
        setTimeout(this.takeScreenshot, 400);
      },
  
      shareCard(title, data) {
        // 小程序分享
        if (isMiniApp()) {
          this.miniAppShare(title, data)
        } else {
          this.shareImage = true
          this.imageUrl = data;
          Toast('网页分享')
        }
      },
      miniAppShare(title, card_id) {
        const url = `/page/share?page_id=${this.page_id}&card_id=${card_id}&title=${title}`
        window.wx.miniProgram.navigateTo({
          url: url,
          fail: function (res) {
            // Toast('跳转失败')
          },
          success: function () {
            // Toast('跳转成功')
          }
        })
      },
      // 右上角菜单操作
      handleSelected(selected, selectedIndex) {
        ActionSheet.close();
        if (selected == ITEM_SHARE ) {
          this.sharePage()
        } else if (selected == ITEM_PUBLIC || selected == ITEM_PRIVATE ) {
          let state = selected == '设为公开' ? 1 : 0;
          this.setPublicState(state)
        } else if (selected == ITEM_DELETE) {
          this.delPage()
        }
      },
      loadPageInfo() {
        api.get('/pages/' + this.page_id)
          .then(response => {
            const result = response.data
            if (result.status == 'success')
            {
              this.page = result.data;
              if (this.isLogin) {
                this.isLiked = result.is_liked
                this.isCollected = result.is_collected
                this.updateActionItems()
              }
            }
          })
      },
      updateActionItems() {
        if (this.isAccount(this.page.user_uuid)) {
            const item_name = this.page.is_public == 0 ? ITEM_PUBLIC : ITEM_PRIVATE;
            this.action_items = [ITEM_SHARE, item_name, ITEM_DELETE]
        }
      },
      // 修改页面属性 0 私密 1 公开 
      setPublicState(open) {
        let params = {public: open}
        api.post('/pages/' + this.page_id + '/setting', params)
       .then(response => {
            const result = response.data.status;
            if (result =='success') {
              this.page.is_public = open
              Toast.success('修改成功')
              this.updateActionItems()
            } else {
              Toast.error('修改失败')
            }
          })
  
      },
      delPage() {
          api.post('/pages/delete', {
            page_id: this.page_id,
          }).then(response => {
            Toast.success('删除成功');
            
            const pageStore = usePageStore();
            pageStore.removePage(this.page_id)
  
            setTimeout(() => {
              this.goBack()
            }, 800)
          })
          .catch(error => {
            Toast.error('删除失败');
            console.error(error);
          });
      },
      showUser(user_id) {
        this.$UMRecord('showUser', {user_id: user_id});
        this.$router.push('/users/' + user_id);
      },
      takeScreenshot() {
        const element = document.querySelector('#gututu-page');
        html2canvas(element, {scrollX: 0, scrollY: -window.scrollY}).then((canvas) => {
          try{
            const imgData = canvas.toDataURL('image/png');
            this.shareImage = true
            this.imageUrl = imgData;
            this.is_share = 0
          } catch (error) {
            console.log(error)
          }
          // const link = document.createElement('a');
          // link.download = 'screenshot.png';
          // link.href = imgData;
          this.is_loading = false
          // link.click();
        })
      },
  
      handleTouchStart(e) {
        if (!isMiniApp()) {
          // 非小程序环境，才需要处理长按事件
          const longPressDuration = 800; // 长按时间阈值，单位毫秒
          this.longPressTimer = setTimeout(() => {
              // 长按操作，触发保存图片
              this.saveImage();
          }, longPressDuration);
        } 
      },
  
      handleTouchMove (e) {
      },
      handleTouchEnd() {
        if (!isMiniApp()) {
          clearTimeout(this.longPressTimer);
        } 
      },
      saveImage() {
        const link = document.createElement('a');
        link.href = this.imageUrl;
        link.download = 'gututu_ai_chart.png'; // 指定下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      showAddCard() {
        this.prompt = ''
        this.addCard = true
      },

      checkPrompt() {
        if (this.prompt == '') {
          this.dialog('请输入查询提示语')
          return false
        }

        if (this.prompt.length <= 4) {
          this.dialog('请输入不少于5个字的查询提示语')
          return false
        }

        if (this.prompt.length >= 150) {
          this.dialog('提示语不能超过150个字')
          return false
        }
        return true
      },
      renderCard() {
        // if (!this.isLogin) {
        //   this.$router.push('/login?from=' + this.$route.path);
        //   return
        // }
        if (!this.checkPrompt()) {
          return;
        }

        if (this.modeling) {
          Toast("请等待，卡片正在生成中...")
          return
        }
  
        var _self = this
        _self.modeling = true
        this.startRunning()
        this.scrollToEnd()

        var url = '/magic?page_id=' + this.page_id
        let params = { prompt: this.prompt }
        if (!this.isLogin) {
          params['visitor_id'] = this.canvasStore.getVisitorId()
        }

        console.log('gututu', params)
    
        api.post(url, params).then(response => {
                const result = response.data
                if (result.status == 'success') {
                  const new_card = result.data
                  _self.addCard = false
                  _self.prompt = ''
                  _self.cardStore.addCard(_self.page_id, new_card)
                  _self.scrollToEnd()
                  _self.autoUpdatePageTitle()
                } else {
                  this.message = result.message
                  this.upgradeDialog = true
                }
            }).finally(() => {
              console.log('gututu', 'finally')
              _self.status = '正在思考中'
              _self.modeling = false
              _self.canvasStore.done()
              _self.getPromptLimit()
            })
      },
      // 第一个卡片生成后，自动更新页面标题
      autoUpdatePageTitle() {
        const cards = this.cardStore.pages[this.page_id]
        if (cards.length == 1) {
          this.page.title = cards[0].title
          this.updatePageTitle()
        }
      },
      scrollToLast() {
        setTimeout(() => {
          var list = document.getElementsByClassName('h5-page')[0]
          const position = this.canvasStore.restoreScrollPosition()
          if (position > 0) {
            list.scrollTop = position
          }
        }, 150)
      },
      scrollToEnd() {
        // 滚动到页面底部
        setTimeout(() => {
          var list = document.getElementsByClassName('h5-page')[0]
          if (list)
            list.scrollTop = list.scrollHeight
        }, 200)
      }
    }
  };
  </script>
  
  <style scoped>
  
  .like-animation-enter-active {
    transition: all 0.25s ease-out;
  }
  .like-animation-enter-from{
    opacity: 0;
    transform: translateY(30px);
  }
  
  .like-animation-leave-active
  .like-animation-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
  
  .card-list {
    padding: 0px 3px; margin-top:20px; margin-bottom:10px;
  }
  .card-form {
    padding: 10px 25px;
  }
  .page-bg {
      background: #EDEDED;
  }
  
  .player-enter,
  .player-leave-to {
    opacity: 0;
  }
  
  .player-enter {
    transform: translateY(30%);
  }
  
  .player-leave-to {
    transform: translateX(300%);
  }
  
  .player-leave-active {
    position: absolute;
  }
  
  .page-header {
    padding: 10px;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  }
  
  
  /* 渐变色 https://www.creative-tim.com/twcomponents/gradient-generator/ */
  /* https://www.grabient.com/ */
  
  .grabient-style {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  }
  
  .grabient-style2 {
    background-color: #FFE53B;
    background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);
  }
  
  .grabient-style3 {
  
  
  }
  
  
  #gututu-page {
    height: fit-content;
  }
  
  .page-title {
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    margin-top:70px;
    margin-left:15px;
    margin-bottom: 10px;
  }
  
  .footer-bar {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: calc(56px + env(safe-area-inset-bottom));
      border-top: #ECECEC solid 1px;
      background-color: #FFF;
      color: white;
      /* display: flex; */
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
      /* box-shadow: 0 -2px 4px rgba(0,0,0,0.1); */
      z-index: 1000; 
      padding-bottom: constant(safe-area-inset-bottom); 
      padding-bottom: env(safe-area-inset-bottom); 
    }
  
    .bottom-popup {
      padding-bottom: constant(safe-area-inset-bottom); 
      padding-bottom: env(safe-area-inset-bottom); 
    }
  
    .popup-desc {
      clear:both; width: 100%;display: flex; padding-bottom: 8px;
    }
  
    .image-to-save {
      width: 90%; /* 容器宽度 */
      height: 80%; 
      overflow: hidden; /* 隐藏超出部分 */
      position: fixed;
      top: 60px;
      left: 30px;
      z-index: 9999;
      padding: 15px;
      background: #fff;
    }
  
    .image-to-save img {
      position: absolute;
      left: 0; /* 从左边开始 */
      top: 0;
      width: auto; /* 自动宽度 */
      height: auto; /* 高度填满容器 */
      object-fit: cover;
    }
  
    /* 只显示图片的前30% */
    .image-to-save::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 30%;
      height: 100%;
    }
  
    .input-container {
      padding: 10px;
      position: relative;
      width:100%;
    }

    .input-field {
      width: 100%;
      padding: 8px 15px;
      font-size: 16px;
      border: 1px solid #e5e5e5;
      border-radius: 25px;
      box-sizing: border-box;
      transition: border-color 0.3s;
      outline: none;
      border-color: #0d6efd;
    }

    .send-button {
      top: 13px;
      right: 20px;
      position: absolute;
      z-index: 1;
      /* color: var(--msh-chat-send-button-color);
      background-color: var(--msh-chat-send-button-bg); */
      border-radius: 10px;
      height: 35px;
      width: 35px;
    }

   
  </style>