<template>
    <div>  
      <div id="share" class="card-list page-bg">
          <CardById
            :card_id="card_id" 
            hide_btn="1"
            show_title="0" />  
      </div>
    </div>
  </template>
  
  <script>
    /* eslint-disable */
  import CardById from '@/components/CardById.vue';
  import { IconFont } from 'tdesign-icons-vue-next';
  import dayjs from 'dayjs';
  

  export default {
    name: 'CardChart',
    components: {
      CardById
    },
    computed: {
     },
    data() {
      return {
        card_id: 0
      }
    },
    created() {
      this.card_id = this.$route.params.card_id;
    },
    methods: {

    }
  };
  </script>
  
  <style scoped>
  
  .card-list {
    padding: 0px;
  }
  .card-form {
    padding: 0px;
  }
  .page-bg {
      background: #EDEDED;
  }
   
  </style>