<template>

   <div id="app" class="container">
      <t-navbar title="移动卡片" :fixed="false" left-arrow @left-click="goBack" />
  
      <!-- <icon-font name="move" style="width: 30px;"/> -->
        <div v-for="(item , index) in cards"
            :key="item.card_id" 
            style=" padding: 10px; border-bottom: 1px solid #ECECEC; position: relative;">
           <div class="clear:both; width:100%;">
               {{ index + 1 }}. {{ item.title }}
           </div> 
           <div style="width: 100%;clear:both;  margin-right: 20px; font-size: 14px; text-align: right;">
               <span v-if="index > 0" @click="moveUp(index)" style="margin-right: 20px;">上移</span>  
               <span v-if="index < (cards.length - 1)" @click="moveDown(index)">下移</span>
           </div>  
        </div>
        <t-button @click="save()" size="large" theme="primary" style="margin:15px; width: 90%;">保存新顺序</t-button>
   </div>
  
</template>

<script>
  /* eslint-disable */
import api from '@/common/api'; 
import { IconFont } from 'tdesign-icons-vue-next';
import { Toast } from 'tdesign-mobile-vue';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    IconFont,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const pageId = route.params.page_id;

    // 定义cards数组
    const cards = ref([]);
    
    // 定义moveUp方法
    const moveUp = (index) => {
      if (index > 0) {
        // 交换当前元素和前一个元素的位置
        const temp = cards.value[index];
        cards.value[index] = cards.value[index - 1];
        cards.value[index - 1] = temp;
      }
    };

    // 定义moveDown方法
    const moveDown = (index) => {
      if (index < cards.value.length - 1) {
        // 交换当前元素和后一个元素的位置
        const temp = cards.value[index];
        cards.value[index] = cards.value[index + 1];
        cards.value[index + 1] = temp;
      }
    };

    const goBack = () => {
      router.back();
    };

    const loadData = () => {
        api.get('/pages/' + pageId + '/cards')
        .then(response => {
            
            const result = response.data;
            if (result.status == 'success') {
                cards.value = result.data;
            } else {
                Toast('加载失败')
            }
        })
    };

    const save = () => {
      // 检查cards的元素属性与index是否有变化，找出有变化的元素
      var changedCards = [];

      cards.value.forEach((card, index) => {
        if (index + 1 != card.list_position)
        changedCards.push({card_uuid: card.card_uuid, title: card.title, list_position: index + 1});
      });

      // if (changedCards.length == 0) {
      //   Toast('没有修改');
      //   return
      // }

      // // 发送请求更新cards的元素属性
      api.post('/pages/' + pageId + '/adjust', { cards: JSON.stringify(changedCards) })
       .then(response => {
          const result = response.data;
          if (result.status =='success') {
            Toast('保存成功');
          } else {
            Toast('保存失败')
          }
        });

    }


    onMounted(() => {
      loadData();
    });

    return {
      cards,
      goBack,
      moveUp,
      moveDown,
      save,
      loadData,
    };
  }
};
</script>


<style scoped>
</style>