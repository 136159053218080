<template>
  <div>
    <Pie :data="data" :options="options" />
  </div>
</template>

<script>
/* eslint-disable */
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'vue-chartjs'
import { generateRandomColors } from '../common/tools'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

export default {
  name: 'PieCard',
  components: {
    Pie
  },
  props: {
    columns: Array,
    chartData: Array
  },
  data() {
    return {
      data: {
        labels: Object.keys(this.chartData[0]),
        datasets: [
          { 
            data: Object.values(this.chartData[0]),
            backgroundColor: generateRandomColors(Object.keys(this.chartData[0]).length) }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          // https://chartjs-plugin-datalabels.netlify.app/guide/getting-started.html#registration
          datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    ctx.dataset.data.forEach((data) => {
                      sum += parseInt(data);
                    });
                    let percentage = Math.round((value / sum) * 100) + '%';
                    return percentage
                },
                color: '#fff',
                labels: {
                title: {
                  font: {
                    size: 16,
                    weight: 'bold'
                  }
                }
              }
            },
        // tooltip: {
        //     callbacks: {
        //       label: function(context) {
        //                 let label = context.dataset.label || 'a';
        //                 // console.log(context)
                        
        //                 let sum = 0;
        //                 context.dataset.data.forEach((data) => {
        //                   sum += parseInt(data);
        //                 });
        //                 // console.log(sum)
        //                 let percentage = Math.round((context.parsed / sum) * 100) + '%';
      
        //                 return percentage;
        //             }
        //     }
        // }
      }
      }
    }
  }
};
</script>