<template>
  <div>
    <Line :data="data" :options="options" />
  </div>
</template>

<script>
/* eslint-disable */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
import { getRandomColor } from '../common/tools'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default {
  name: 'LineCard',
  components: {
    Line
  },
  props: {
    columns: Array,
    chartData: Array
  },
  data() {
    return {
      data: {
        labels: Object.keys(this.chartData[0]),
        datasets: this.chartData.map((data, index) => {
          const color = getRandomColor()
          return {
            label: this.columns[index],
            data: Object.values(data),
            borderColor: color, // 交替颜色
            backgroundColor: color
          }
        })
      },
      options: {
        responsive: true
      }
    }
  }
};
</script>