<template>
  <div class="h5-page">
    <t-navbar title="个人信息收集清单" :fixed="false" left-arrow @left-click="goBack">
  </t-navbar>

    <div id="app" class="container">
        <t-cell-group>
            <t-cell title="用户基础信息" description="创建帐号、对外展示" note="" arrow />
            <t-cell title="手机号码" description="创建帐号、登录、认证" note="" arrow />
            <t-cell title="第三方账号信息" description="联合登录" note="" arrow />
            <!-- <t-cell title="实名信息" description="依据相关法规要求" note="" arrow /> -->
            <t-cell title="收藏记录" description="" note="" arrow />
            <t-cell title="交易记录" description="" note="" arrow />
            <t-cell title="搜索记录" description="" note="" arrow />

            <t-cell title="设备标识信息" description="" note="" arrow />
            <t-cell title="设备参数及系统信息" description="" note="" arrow />
        </t-cell-group>
   </div>
  </div>
 </template>
 
 <script>
   /* eslint-disable */
   import 'bootstrap/dist/css/bootstrap.min.css';
 export default {
   name: 'Agreement',
   components: {
     
   },
   data() {
    return {
      update_date: '8月10日, 2024年',
      company_name: 'Gututu',
      email: 'hello@gututu.com',
    }
   },
   created() {

   },
   methods: {
      goBack() {
        this.$router.back();
      },
   }
 };
 </script>
 <style scoped>
    p {margin-top: 50px;}
 </style>