<template>
    <div class="list-chart">
      <!-- table-striped -->
      <table class="table list">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column">{{ formattedColumn(column) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in chartData" :key="index" :class="(selectIndex == index ? 'active' : '')"
           @click="gotoStock(item)" @touchstart="handleTouchStart(index)">
            <td v-for="(column, idx) in columns" :key="idx">
              <span v-if="columns[idx] == '代码'" >
                 {{ item[idx] }}
              </span>
              <span v-else-if="isChange(columns[idx])">
                 <span class="stock_change stock_green" v-if="item[idx] < 0">{{ item[idx] }}%</span>
                 <span class="stock_change stock_red" v-else>{{ item[idx] }}%</span>
              </span>
              <span v-else>
                {{ formattedData(item[idx], idx) }}
              </span>
              <!-- <ListValue column_name="涨幅" :value="item[idx]"></ListValue> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import ListValue from './ListValue.vue';

  export default {
    name: 'ListCard',
    components: {
        ListValue
    },
    props: {
      columns: Array,
      chartData: Array
    },
    computed: {

    },
    data() {
      return {
        selectIndex: -1,
      }
    }, 
    methods: {
      isChange(column_name) {
        if (column_name.includes('涨幅'))
          return true
        else if (column_name.includes('跌幅'))
          return true
        else
          return false
      },
      handleTouchStart(index) {
        this.selectIndex = index;
      },
      gotoStock(stock) {
        this.$router.push('/stock/' + stock[0]);
      }, 
      formattedColumn(value) {
        if (value.includes('交易额') && !value.includes('亿')) {
          return value + '(亿)'
        } else if (value.includes('市值') && !value.includes('亿')) {
          return value + '(亿)'
        } else if (value.includes('成交额') && !value.includes('亿')) {
          return value + '(亿)'
        }
        return value
      },
      formattedData(value, index) {
        if (value == null) {
          return '-'
        }

        const column_name = this.columns[index]
        if (column_name.includes('涨幅') || column_name.includes('涨跌')) {
          // 只保留1位小数点，显示%号
          if (value == null) {
            return '-'
          } else {
            return parseFloat(value).toFixed(1) + '%'
          }
        } else if (column_name.includes('市值')) {
          return parseInt(value / 1e8)
        } else if (column_name.includes('成交额') && (!column_name.includes('亿'))) {
          return (parseInt(value) / 1e8).toFixed(1)
        } else if (column_name.includes('交易额') && (!column_name.includes('亿'))) {
          return (parseInt(value) / 1e8).toFixed(1)
        } else if (column_name.includes('市盈率')) {
          return value.toFixed(0)
        }
        
        return value
      }
    }
  };
  </script>

<style scoped>
.list-chart {
    width:100%; overflow-x: auto; 
}


.table .active td{
  background-color: #e3f9e9 /* 点击时的背景色 */;
}
</style>