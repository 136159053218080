/* eslint-disable */
const getRandomColor = () => {
  // 生成随机的RGB颜色值
  const r = Math.floor(Math.random() * 256); // R分量
  const g = Math.floor(Math.random() * 256); // G分量
  const b = Math.floor(Math.random() * 256); // B分量

  const colors = ['#FE6009', '#C850C0', '#4158D0', '#0052D9', '#00726D', '#00BCBD']
  const randomIndex = Math.floor(Math.random() * colors.length);
  // 随机选择一个颜色
  const selectedColor = colors[randomIndex];
  return selectedColor;
  // return `rgb(${r},${g},${b})`; // 返回RGB颜色字符串
};

// 定义一个函数来生成指定数量的随机颜色数组
const generateRandomColors = (count) => {
  return Array.from({ length: count }, getRandomColor);
}

// 是否在微信环境内
const isWechatEnv = () => {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.includes('micromessenger') && !ua.includes('miniprogram')) {
    return true
  } else {
    return false
  }
}

const isMiniApp = () => {
  // 判断是否在微信小程序
  var ua = navigator.userAgent.toLowerCase();
  if (ua.includes('micromessenger') && ua.includes('miniprogram')) {
    return true
  } else {
    return false
  }
}




export { getRandomColor, generateRandomColors, isWechatEnv, isMiniApp };